import React, { useState } from 'react';
import {
  BooleanInput,
  Edit,
  EditProps,
  RadioButtonGroupInput,
  ReferenceArrayInput,
  ReferenceArrayInputProps,
  ReferenceInput,
  required,
  SaveButton,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  TitleProps,
  Toolbar,
  useGetIdentity,
  useGetOne,
  usePermissions,
  useRecordContext,
} from 'react-admin';
import { useFormState } from 'react-final-form';

import { Clearance, Permissions } from 'utils/interfaces';
import { ClearanceOrderMap, passesClearance } from 'utils';

import { uiStringsEt } from '../../../../i18n/ui.strings';

// to fake IDE locale plugin
const t = (s: string) => s;

const clearanceOptions: { id: keyof typeof Clearance; name: string }[] = [
  {
    id: Clearance.ROOT,
    name: t('app.user.create.fields.clearance.option.root'),
  },
  {
    id: Clearance.CLIENT,
    name: t('app.user.create.fields.clearance.option.client'),
  },
  {
    id: Clearance.RESELLER,
    name: t('app.user.create.fields.clearance.option.reseller'),
  },
  {
    id: Clearance.SITE,
    name: t('app.user.create.fields.clearance.option.site'),
  },
];

const getUserTypeChoiceBasedOnCurrentRecordClearance = (
  clearance: string | null,
) => {
  if (clearance) {
    switch (clearance) {
      case Clearance.ROOT:
        return [
          {
            id: '6e077624-29f4-44e2-a109-bc759808267f',
            name: 'Admin',
          },
        ];
      case Clearance.RESELLER:
        return [
          {
            id: '4d3fc63f-66fe-45c9-8bb0-55f32f2b72b4',
            name: 'Edasimüüja',
          },
        ];
      case Clearance.CLIENT:
        return [
          {
            id: '9b367f02-77e0-43b8-85bb-3f8fca6ca35d',
            name: 'Lõppklient',
          },
        ];
      default:
        return [];
    }
  }
  return [];
};

const ClearanceReferenceArrayInput: React.FC<
  ReferenceArrayInputProps & {
    minClearance: Clearance;
  }
> = ({
  minClearance,
  ...props
}: ReferenceArrayInputProps & {
  minClearance: Clearance;
}) => {
  const { values } = useFormState<{ clearance: Clearance }>();
  const { permissions } = usePermissions<Permissions>();
  if (
    ClearanceOrderMap[values.clearance] < ClearanceOrderMap[minClearance] ||
    ClearanceOrderMap[minClearance] >
      ClearanceOrderMap[permissions?.clearance ?? Clearance.UNDEFINED]
  ) {
    return null;
  }
  return <ReferenceArrayInput {...props} />;
};
const CustomToolbar: React.FC = (toolbarProps) => (
  <Toolbar {...toolbarProps}>{/* Omit the save button */}</Toolbar>
);

const Title = ({ record }: TitleProps): React.ReactElement => (
  <>User - {record?.username}</>
);

const UserEdit = ({ permissions, ...props }: EditProps): React.ReactElement => {
  const options = clearanceOptions.filter((clearance) =>
    passesClearance(permissions?.clearance, clearance.id),
  );

  const { loaded, identity } = useGetIdentity();
  const loggedInUserDataId = identity ? identity.id : '';
  const {
    data: loggedInuser,
    loading,
    error,
  } = useGetOne('user', loggedInUserDataId);
  const userClearance = loggedInuser ? loggedInuser.clearance : null;

  const editingUserDataId = props.id ? props.id : '';
  const { data: editingUser } = useGetOne('user', editingUserDataId);

  const [selectedUserType, setSelectedUserType] = useState<any>(null);

  return (
    <Edit {...props} title={<Title />}>
      <SimpleForm toolbar={<CustomToolbar />}>
        {editingUser ? (
          <RadioButtonGroupInput
            label={false}
            source="userGroupId"
            choices={getUserTypeChoiceBasedOnCurrentRecordClearance(
              editingUser.clearance,
            )}
          />
        ) : null}
        <TextInput
          label={uiStringsEt.usernameLabel}
          source="username"
          disabled
        />
        <TextInput source="email" type="email" disabled />
        {editingUser && editingUser.reseller ? (
          <TextInput
            label="Edasimüüja"
            source="resellerId"
            defaultValue={editingUser.reseller.name}
            disabled
          />
        ) : null}
        {editingUser && editingUser.client ? (
          <TextInput
            label="Lõpp-klient"
            source="clientId"
            defaultValue={editingUser.client.name}
            disabled
          />
        ) : null}
      </SimpleForm>
    </Edit>
  );
};

export default UserEdit;
