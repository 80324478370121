import {
  Typography,
  Button,
  Paper,
  Modal,
  makeStyles,
} from '@material-ui/core';
import React, { useState } from 'react';
import {
  Datagrid,
  DateField,
  EditButton,
  FieldProps,
  Filter,
  FilterProps,
  FunctionField,
  List,
  ListProps,
  RadioButtonGroupInput,
  TextInput,
  useListContext,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import styled from 'styled-components';

import { Clearance, Permissions } from 'utils/interfaces';
import { useCanEdit, useCanOpenClient } from 'app/hooks';
import BackendService from 'service/BackendService';

import { uiStringsEt } from '../../../../i18n/ui.strings';

type SensorModelReportClientSiteProps = {
  id: number;
  cycles: number;
  maintenanceCycle: number;
  batteryLife: number;
};

const useFilterStyles = makeStyles((theme) => ({
  search: {
    //  border: '1px solid red',
  },
  active: {
    marginTop: 0,
    marginBottom: 0,
    //  border: '1px solid red',
  },
}));

const TestFilter = (props: Partial<FilterProps>) => {
  const classes = useFilterStyles();

  return (
    <Filter {...props}>
      <TextInput
        label="Otsi"
        source="deviceId||$starts"
        alwaysOn
        className={classes.search}
      />

      <RadioButtonGroupInput
        source="saleDate||$notnull"
        alwaysOn
        className={classes.active}
        label=""
        choices={[
          { id: '', name: 'Kõik' },
          { id: 'notnull', name: 'Müüdud' },
          { id: 'null', name: 'Müümata' },
        ]}
      />
    </Filter>
  );
};

const ConfirmModalPaper = styled(Paper)`
  position: absolute;
  width: 25rem;
  padding: 2rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: auto auto;
  justify-items: center;
  row-gap: 3rem;

  span {
    grid-column: 1 / span 2;
  }

  button {
    grid-area: 2;
    min-width: 8rem;
  }
`;

const CycleResetButton: React.FC<FieldProps<SensorModelReportClientSiteProps>> =
  (props) => {
    const t = useTranslate();
    const { id } = useRecordContext(props);
    const { refetch } = useListContext();
    const [modalOpen, setModalOpen] = useState(false);

    const handleReset = async () => {
      await BackendService.resetSensor(id);
      refetch();
      setModalOpen(false);
    };
    return (
      <>
        <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
          <ConfirmModalPaper>
            <Typography component="span" variant="subtitle1">
              {t('app.sensors.list.reset.confirm.label')}
            </Typography>
            <Button variant="contained" color="primary" onClick={handleReset}>
              {t('app.labels.yes')}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setModalOpen(false)}
            >
              {t('app.labels.no')}
            </Button>
          </ConfirmModalPaper>
        </Modal>
        <Button onClick={() => setModalOpen(true)}>
          {t('app.sensors.list.reset.label')}
        </Button>
      </>
    );
  };

const SensorListView = ({
  permissions,
  ...props
}: ListProps & { permissions?: Permissions }): React.ReactElement => {
  const canEdit = useCanEdit('sensor', permissions);
  const canOpenClient = useCanOpenClient(permissions);
  return (
    <List
      {...props}
      exporter={false}
      perPage={25}
      bulkActionButtons={false}
      filters={<TestFilter />}
    >
      <Datagrid>
        <FunctionField
          source="deviceId"
          label="Anduri ID/SIM ICCID"
          render={(record: any) => {
            if (record !== undefined) {
              return (
                <span style={{ display: 'flex', flexDirection: 'column' }}>
                  <span>{record.deviceId}</span>
                  <span style={{ color: '#999999' }}>{record.simId}</span>
                </span>
              );
            }
            return record;
          }}
        />
        <FunctionField
          source="status"
          label={uiStringsEt.statusLabel}
          render={(record: any) => {
            if (record !== undefined) {
              switch (record.status) {
                case 'active':
                  return 'Aktiveeritud';
                case 'deactivated':
                  return 'Desaktiveeritud';
                default:
                  return '-';
              }
            }
            return record;
          }}
        />
        {permissions && permissions.clearance !== Clearance.RESELLER ? (
          <FunctionField
            source="resellerId"
            label={uiStringsEt.resellerNameLabel}
            render={(record: any) => {
              if (record !== undefined) {
                if (record.reseller) {
                  return `${record.reseller.name}`;
                }
                return '—';
              }
              return record;
            }}
          />
        ) : null}

        <FunctionField
          source="activateDate"
          label="Aktiveerimise k.p"
          render={(record: any) => {
            if (record !== undefined) {
              if (record.activateDate) {
                return (
                  <DateField
                    source="activateDate"
                    label="Aktiveerimise k.p"
                    showTime
                    locales={['et', 'en']}
                  />
                );
              }
              return '—';
            }
            return record;
          }}
        />
        <FunctionField
          source="saleDate"
          label="Müügi k.p"
          render={(record: any) => {
            if (record !== undefined) {
              if (record.saleDate) {
                return (
                  <DateField
                    source="saleDate"
                    label="Aktiveerimise k.p"
                    showTime
                    locales={['et', 'en']}
                  />
                );
              }
              return '—';
            }
            return record;
          }}
        />
        {canEdit && <EditButton label={uiStringsEt.editButtonLabel} />}
      </Datagrid>
    </List>
  );
};
export default SensorListView;
