import { Button, makeStyles, Modal, Paper, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
  DeleteButton,
  Edit,
  EditProps,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  TitleProps,
  Toolbar,
  useQuery,
  Record as RARecord,
  TopToolbar,
  EditActionsProps,
  useTranslate,
  useRecordContext,
} from 'react-admin';
import styled from 'styled-components';

import ReplaceModal from 'app/components/ReplaceModal';
import BackendService from 'service/BackendService';

const Title = ({ record }: TitleProps): React.ReactElement => (
  <>Sensor - {record?.shortDescription}</>
);

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  }
});

type SensorModelReportClientSiteProps = {
  id: number;
  cycles: number;
};

const ConfirmModalPaper = styled(Paper)`
  position: absolute;
  width: 30rem;
  padding: 2rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: auto auto;
  justify-items: center;
  row-gap: 3rem;

  span {
    grid-column: 1 / span 2;
    text-align: center;
  }

  button {
    grid-area: 2;
    min-width: 8rem;
  }
`;

const useDeleteButton = (record?: RARecord): boolean => {
  const [disabled, setDisabled] = useState(true);
  const sensorEvent = useQuery({
    type: 'getList',
    resource: 'sensor-event-list-view',
    payload: {
      pagination: { page: 1, perPage: 1 },
      filter: { 'sensorId||$eq': record?.id },
    },
  });
  const sensorReport = useQuery({
    type: 'getList',
    resource: 'sensor-report',
    payload: {
      pagination: { page: 1, perPage: 1 },
      filter: { 'sensorId||$eq': record?.id },
    },
  });
  useEffect(() => {
    if (sensorReport !== undefined && sensorEvent !== undefined) {
      if ((sensorReport.total ?? 1) > 0 || (sensorEvent.total ?? 1) > 0) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }
  }, [sensorEvent, sensorReport]);
  return disabled;
};

const CycleResetButton =
  (props:any) => {
    const t = useTranslate();
    const { id, cycles } = useRecordContext(props);
    const [modalOpen, setModalOpen] = useState(false);

    const handleReset = async () => {
      const newId:any = id.toString();
      await BackendService.resetController(newId, cycles);
      setModalOpen(false);
    };
    return (
      <>
        <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
          <ConfirmModalPaper>
            <Typography component="span" variant="subtitle1">
              {t('app.sensors.list.reset.confirm.label')}
            </Typography>
            <Button variant="contained" color="primary" onClick={handleReset}>
              {t('app.labels.yes')}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setModalOpen(false)}
            >
              {t('app.labels.no')}
            </Button>
          </ConfirmModalPaper>
        </Modal>
        <Button variant='outlined' color='primary' onClick={() => setModalOpen(true)}>
          {t('app.sensors.list.reset.label')}
        </Button>
      </>
    );
  };

const SensorToolbar: typeof Toolbar = (props) => {
  const { record } = props;
  const disabled = useDeleteButton(record);
  return (
    <Toolbar {...props} classes={useStyles()}>
      <SaveButton />
      <DeleteButton disabled={disabled} />
    </Toolbar>
  );
};

const SensorEditActions = ({
  basePath,
  data
}: EditActionsProps): React.ReactElement => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const editSensorId = data?.id as string;
  const datas = {
    id: data?.id,
    cycles: data?.cycles,
  };

  return (
    <TopToolbar>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setIsModalOpen(true)}
      >
        Replace
      </Button>
      <ReplaceModal
        editSensorId={editSensorId ?? ''}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        basePath={basePath}
        isCreate={false}
        sensorId={data?.deviceId}
      />
      <CycleResetButton />
    </TopToolbar>
  );
};

const SensorEdit = (props: EditProps): React.ReactElement => (
  <div>
    <Edit {...props} title={<Title />} actions={<SensorEditActions />}>
    <SimpleForm toolbar={<SensorToolbar />}>
      <TextInput source="deviceId" validate={[required()]} />
      <TextInput
        source="directoId"
        validate={[required()]}
        label="Directo id"
      />
      <TextInput
        source="shortDescription"
        validate={[required()]}
        label="Name"
      />
      <TextInput source="longDescription" label="Description" />
      <SelectInput
        label="Sensor type"
        allowEmpty
        emptyValue={null}
        source="type"
        choices={[
          { id: 'WiredController', name: 'Wired controller' },
          { id: 'WirelessSensor', name: 'Wireless sensor' },
        ]}
      />
      <ReferenceInput
        reference="door-controller-model"
        source="doorcontrollermodelid"
        label="Door Controller model"
        allowEmpty
        emptyValue={null}
      >
        <SelectInput source="name" />
      </ReferenceInput>
      <ReferenceInput
        reference="door-model"
        source="doorModelId"
        label="Door model"
      >
        <SelectInput source="name" validate={[required()]} />
      </ReferenceInput>
      <ReferenceInput reference="site" source="siteId" label="Site">
        <SelectInput source="name" validate={[required()]} />
      </ReferenceInput>
      <SelectInput
        source="status"
        choices={[
          { id: 'active', name: 'active' },
          { id: 'faulty', name: 'faulty' },
          { id: 'maintenance', name: 'maintenance' },
          { id: 'deactivated', name: 'deactivated' },
        ]}
      />
    </SimpleForm>
    </Edit>
  </div>
);

export default SensorEdit;
