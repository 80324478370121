import React, { ReactElement } from 'react';
import {
  Datagrid,
  Filter,
  FilterProps,
  List,
  ListProps,
  TextField,
  TextInput,
  EditButton,
  ReferenceField,
  SelectArrayInput,
  ReferenceArrayInput,
  FunctionField,
} from 'react-admin';
import {
  addDays,
  addMonths,
  differenceInDays,
  format,
  isValid,
  min,
} from 'date-fns';

import { Permissions } from 'utils/interfaces';
import { useCanEdit, useCanOpenClient } from 'app/hooks';

import { uiStringsEt } from '../../../../i18n/ui.strings';

const SiteFilter = (props: Partial<FilterProps>): ReactElement => (
  <Filter {...props}>
    <TextInput
      label={uiStringsEt.searchLabel}
      source="name||$starts"
      alwaysOn
    />
  </Filter>
);

const GatewayList = ({
  permissions,
  ...props
}: ListProps & { permissions?: Permissions }): React.ReactElement => {
  const canEdit = useCanEdit('site', permissions);
  const canOpenClient = useCanOpenClient(permissions);
  return (
    <List
      {...props}
      exporter={false}
      perPage={25}
      bulkActionButtons={false}
      filters={<SiteFilter />}
    >
      <Datagrid>
        <FunctionField
          source="name"
          label="Kirjeldus / Anduri ID"
          render={(record: any) => {
            if (record !== undefined) {
              return (
                <span style={{ display: 'flex', flexDirection: 'column' }}>
                  <span>{record.name}</span>
                  {record.sensor && record.sensor.deviceId ? (
                    <span style={{ color: '#999999' }}>
                      {record.sensor.deviceId}
                    </span>
                  ) : null}
                </span>
              );
            }
            return record;
          }}
        />
        <ReferenceField
          reference="reseller"
          source="client.resellerId"
          label="Edasimüüja"
        >
          <TextField source="name" />
        </ReferenceField>
        <FunctionField
          source="clientId"
          label={uiStringsEt.clientNameLabel}
          render={(record: any) => {
            if (record !== undefined) {
              if (record.clientId) {
                return (
                  <ReferenceField
                    reference="client"
                    source="clientId"
                    link={canOpenClient}
                  >
                    <TextField source="name" />
                  </ReferenceField>
                );
              }
              return '—';
            }
            return record;
          }}
        />
        <FunctionField
          source="lastMaintenanceCycle"
          label="Tsükleid Hoolduseni / Kokku"
          render={(record: any) => {
            if (record !== undefined) {
              if (record.sensor && record.sensor.totalCycles) {
                let calcToMaintenance = 0;
                if (
                  record.maintenanceCycles !== null &&
                  record.lastMaintenanceCycle !== null
                ) {
                  calcToMaintenance =
                    record.maintenanceCycles -
                    record.sensor.totalCycles +
                    record.lastMaintenanceCycle;
                }
                return (
                  <span style={{ display: 'flex', flexDirection: 'column' }}>
                    <span>{`${
                      calcToMaintenance > 0 ? calcToMaintenance : 0
                    } / ${record.sensor.totalCycles}`}</span>
                  </span>
                );
              }
              return (
                <span style={{ display: 'flex', flexDirection: 'column' }}>
                  <span>0 / 0</span>
                </span>
              );
            }
            return record;
          }}
        />
        <FunctionField
          source="springReplacementCycles"
          label="Vedru vahetamiseni"
          render={(record: any) => {
            if (record !== undefined) {
              if (record.sensor && record.sensor.totalCycles) {
                let calcToSpringReplacement = 0;
                if (
                  record.springReplacementCycles !== null &&
                  record.lastSpringReplacementCycle !== null
                ) {
                  calcToSpringReplacement =
                    record.springReplacementCycles -
                    record.sensor.totalCycles +
                    record.lastSpringReplacementCycle;
                }
                return (
                  <span style={{ display: 'flex', flexDirection: 'column' }}>
                    <span>{`${
                      calcToSpringReplacement > 0 ? calcToSpringReplacement : 0
                    }`}</span>
                  </span>
                );
              }
              return (
                <span style={{ display: 'flex', flexDirection: 'column' }}>
                  <span>0</span>
                </span>
              );
            }
            return record;
          }}
        />
        <FunctionField
          source="lastMaintenanceCycle"
          label="Järgmise hoolduseni (hinnanguline)"
          render={(record: any) => {
            if (record !== undefined) {
              try {
                const addMonthNumber = record.maintenanceInterval
                  ? record.maintenanceInterval
                  : 0;
                const predictedDateOffTimeBasedMaintenance = addMonths(
                  new Date(record.lastMaintenanceDate),
                  addMonthNumber,
                );

                const sensorTotalCycles =
                  record.sensor && record.sensor.totalCycles
                    ? record.sensor.totalCycles
                    : 0;
                const averageCyclesPerDaySinceLastMaintenance =
                  (sensorTotalCycles - record.lastMaintenanceCycle) /
                  differenceInDays(
                    new Date(),
                    new Date(record.lastMaintenanceDate),
                  );
                const daysForFullMaintenanceCycle =
                  record.maintenanceCycles /
                  averageCyclesPerDaySinceLastMaintenance;
                const predictedDateOffCycleBasedMaintenance = addDays(
                  new Date(record.lastMaintenanceDate),
                  daysForFullMaintenanceCycle,
                );

                const compareDates = [];
                if (isValid(predictedDateOffTimeBasedMaintenance)) {
                  compareDates.push(predictedDateOffTimeBasedMaintenance);
                }
                if (isValid(predictedDateOffCycleBasedMaintenance)) {
                  compareDates.push(predictedDateOffCycleBasedMaintenance);
                }
                return (
                  <span style={{ display: 'flex', flexDirection: 'column' }}>
                    <span>{format(min(compareDates), 'dd.M.yyyy')}</span>
                  </span>
                );
              } catch (e) {
                return (
                  <span style={{ display: 'flex', flexDirection: 'column' }}>
                    <span>0</span>
                  </span>
                );
              }
            }
            return record;
          }}
        />
        {canEdit && <EditButton label={uiStringsEt.editButtonLabel} />}
      </Datagrid>
    </List>
  );
};

export default GatewayList;
