import { Typography, Button, Paper, Modal } from '@material-ui/core';
import React, { useState } from 'react';
import {
  Datagrid,
  DateField,
  EditButton,
  FieldProps,
  Filter,
  FilterProps,
  FunctionField,
  List,
  ListProps,
  NumberField,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  TextField,
  TextInput,
  useListContext,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import styled from 'styled-components';

import { Permissions } from 'utils/interfaces';
import { useCanEdit, useCanOpenClient } from 'app/hooks';
import BackendService from 'service/BackendService';

import { uiStringsEt } from '../../../../i18n/ui.strings';

type SensorModelReportClientSiteProps = {
  id: number;
  cycles: number;
  maintenanceCycle: number;
  batteryLife: number;
};
const CustomField: React.FC<FieldProps<SensorModelReportClientSiteProps>> = (
  props,
) => {
  const { maintenanceCycle, cycles } = useRecordContext(props);
  const res = maintenanceCycle - cycles;
  const percentage = cycles / maintenanceCycle;
  let color = 'red';
  if (Number.isFinite(percentage)) {
    if (percentage <= 0.7) {
      color = 'green';
    } else if (percentage <= 0.9) {
      color = 'yellow';
    }
  }
  return (
    <Typography variant="body2" component="span" style={{ color }}>
      {res}
    </Typography>
  );
};

const ListFilter = (props: Partial<FilterProps>) => (
  <Filter {...props}>
    <TextInput label={uiStringsEt.searchName} source="shortDescription" />
    <ReferenceInput
      label={uiStringsEt.searchDeviceId}
      source="id||$eq"
      reference="sensor"
      allowEmpty
    >
      <SelectInput optionText="deviceId" />
    </ReferenceInput>
    <ReferenceArrayInput
      label={uiStringsEt.searchDoorModel}
      source="doorModelId||$in"
      reference="door-model"
      allowEmpty
    >
      <SelectArrayInput optionText="name" />
    </ReferenceArrayInput>
    <ReferenceArrayInput
      label={uiStringsEt.searchGateway}
      source="siteId||$in"
      reference="gateway"
      allowEmpty
    >
      <SelectArrayInput optionText="name" />
    </ReferenceArrayInput>
    <ReferenceArrayInput
      label={uiStringsEt.searchClient}
      source="clientId||$in"
      reference="client"
    >
      <SelectArrayInput optionText="name" />
    </ReferenceArrayInput>
    <SelectArrayInput
      optionText="name"
      label={uiStringsEt.searchStatus}
      source="status||$in"
      choices={[
        { id: 'active', name: 'Active' },
        { id: 'faulty', name: 'Faulty' },
        { id: 'maintenance', name: 'Maintenance' },
        { id: 'deactivated', name: 'Deactivated' },
      ]}
    />
  </Filter>
);

const ConfirmModalPaper = styled(Paper)`
  position: absolute;
  width: 25rem;
  padding: 2rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: auto auto;
  justify-items: center;
  row-gap: 3rem;

  span {
    grid-column: 1 / span 2;
  }

  button {
    grid-area: 2;
    min-width: 8rem;
  }
`;

const CycleResetButton: React.FC<FieldProps<SensorModelReportClientSiteProps>> =
  (props) => {
    const t = useTranslate();
    const { id } = useRecordContext(props);
    const { refetch } = useListContext();
    const [modalOpen, setModalOpen] = useState(false);

    const handleReset = async () => {
      await BackendService.resetSensor(id);
      refetch();
      setModalOpen(false);
    };
    return (
      <>
        <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
          <ConfirmModalPaper>
            <Typography component="span" variant="subtitle1">
              {t('app.sensors.list.reset.confirm.label')}
            </Typography>
            <Button variant="contained" color="primary" onClick={handleReset}>
              {t('app.labels.yes')}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setModalOpen(false)}
            >
              {t('app.labels.no')}
            </Button>
          </ConfirmModalPaper>
        </Modal>
        <Button onClick={() => setModalOpen(true)}>
          {t('app.sensors.list.reset.label')}
        </Button>
      </>
    );
  };

const SensorListView = ({
  permissions,
  ...props
}: ListProps & { permissions?: Permissions }): React.ReactElement => {
  const canEdit = useCanEdit('sensor', permissions);
  const canOpenClient = useCanOpenClient(permissions);
  return (
    <List {...props} filters={<ListFilter />} exporter={false} perPage={25}>
      <Datagrid>
        <ReferenceField
          reference="client"
          source="clientId"
          label={uiStringsEt.clientNameLabel}
          link={canOpenClient}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField reference="gateway" source="siteId" label={uiStringsEt.gatewayNameLabel}>
          <TextField source="name" />
        </ReferenceField>
        <TextField source="shortDescription" label={uiStringsEt.sensorNameLabel} />
        <ReferenceField label={uiStringsEt.doorModelLabel} source="doorModelId" reference="door-model">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          source="sensorEventId"
          reference="sensor-event-list-view"
          label={uiStringsEt.lastSensorEventLabel}
          link={({ id }) =>
            `sensor-event-list-view?filter=${JSON.stringify({
              'sensorId||$eq': id,
            })}`
          }
        >
          <DateField source="time" showTime locales={['et', 'en']} />
        </ReferenceField>
        <FunctionField<SensorModelReportClientSiteProps>
          source="batteryLife"
          label={uiStringsEt.batteryLevelLabel}
          render={(record) => <>{record?.batteryLife ?? 0}%</>}
        />
        <CustomField label={uiStringsEt.cyclesToMaintenanceLabel} />
        <NumberField label={uiStringsEt.cyclesLabel} source="cycles" />
        <NumberField label={uiStringsEt.maintenanceCycleLabel} source="maintenanceCycle" />
        <TextField label={uiStringsEt.statusLabel} source="status" />
        {/* <TextField source="type" /> */}
        <TextField source="deviceId" label={uiStringsEt.sensorIdLabel} />
        {canEdit && <EditButton label={uiStringsEt.editButtonLabel} />}
        {canEdit && <CycleResetButton label={uiStringsEt.resetButtonLabel} />}
      </Datagrid>
    </List>
  );
};
export default SensorListView;
