import React, { FC } from 'react';
import { useInput, TextInput, Button, InputProps } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Loop } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';

import BackendService from '../../../../service/BackendService';

const useStyles = makeStyles({
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    marginLeft: '1em',
  },
});

const GenerateTokenField: FC<InputProps> = (props) => {
  const { source, validate, label, resellerId } = props;
  const {
    input: { name, onChange },
    meta: { touched, error },
    isRequired,
  } = useInput(props);

  const classes = useStyles();

  const handleGenerate = async () => {
    const generatedValueResponse = await BackendService.generateResellerToken(
      resellerId,
    );
    onChange(generatedValueResponse);
  };

  return (
    <Tooltip title="Token on nähtav ainult loomisel. Kopeeri ja salvesta see turvaliselt.">
      <div className={classes.row}>
        <TextInput
          label={label}
          source={source} // pass the source prop to TextInput
          name={name}
          validate={validate}
          helperText={touched && error}
          disabled
        />
        <Button className={classes.button} onClick={handleGenerate}>
          <Loop />
        </Button>
      </div>
    </Tooltip>
  );
};

export default GenerateTokenField;
