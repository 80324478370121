import React, { ReactElement } from 'react';
import {
  Datagrid,
  FilterProps,
  List,
  ListProps,
  NumberField,
  TextField,
  TextInput,
  Filter,
  EditButton,
} from 'react-admin';

import { Permissions } from 'utils/interfaces';
import { useCanEdit } from 'app/hooks';

import { uiStringsEt } from '../../../../i18n/ui.strings';

const DoorModelFilter = (props: Partial<FilterProps>): ReactElement => (
  <Filter {...props}>
    <TextInput label="Search" source="name" alwaysOn />
  </Filter>
);

const DoorModeList = ({
  permissions,
  ...props
}: ListProps & { permissions?: Permissions }): React.ReactElement => {
  const canEdit = useCanEdit('doorModel', permissions);
  return (
    <List {...props} filters={<DoorModelFilter />}>
      <Datagrid>
        <TextField source="name" label={uiStringsEt.modelNameLabel} />
        <NumberField
          label={uiStringsEt.maintenanceCycleLabel}
          source="maintenanceCycle"
        />
        {canEdit && <EditButton label={uiStringsEt.editButtonLabel} />}
      </Datagrid>
    </List>
  );
};

export default DoorModeList;
