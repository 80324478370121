import React from 'react';
import {
  Edit,
  EditProps,
  SelectArrayInput,
  SimpleForm,
  TextInput,
  TitleProps,
} from 'react-admin';

import { Role } from 'utils/interfaces';

import { uiStringsEt } from '../../../../i18n/ui.strings';

export const rights: { id: keyof typeof Role; name: string }[] = [
  { id: 'READ', name: 'READ' },
  { id: 'CREATE', name: 'CREATE' },
  { id: 'UPDATE', name: 'UPDATE' },
  { id: 'DELETE', name: 'DELETE' },
];
export const limitedRights: { id: keyof typeof Role; name: string }[] = [
  { id: 'READ', name: 'READ' },
];

const Title = ({ record }: TitleProps): React.ReactElement => (
  <>User Group - {record?.name}</>
);

const UserGroupEdit = (props: EditProps): React.ReactElement => (
  <Edit {...props} title={<Title />}>
    <SimpleForm>
      <TextInput source="name" label={uiStringsEt.userGroupLabel} />
      <SelectArrayInput
        source="sensor"
        choices={rights}
        label={uiStringsEt.sensorNameLabel}
      />
      <SelectArrayInput
        source="doorControllerModel"
        choices={rights}
        label={uiStringsEt.doorControllerModelLabel}
      />
      <SelectArrayInput
        source="doorModel"
        choices={rights}
        label={uiStringsEt.doorModelLabel}
      />
      <SelectArrayInput
        source="client"
        choices={rights}
        label={uiStringsEt.clientNameLabel}
      />
      <SelectArrayInput
        source="reseller"
        choices={rights}
        label={uiStringsEt.resellerNameLabel}
      />
      <SelectArrayInput
        source="site"
        choices={rights}
        label={uiStringsEt.gatewayNameLabel}
      />
      <SelectArrayInput
        source="controllerEvents"
        choices={rights}
        label={uiStringsEt.controllerEventsLabel}
      />
      <SelectArrayInput
        source="user"
        choices={rights}
        label={uiStringsEt.usersLabel}
      />
      <SelectArrayInput
        source="userGroup"
        choices={rights}
        label={uiStringsEt.userGroupsLabel}
      />
      <SelectArrayInput
        source="deviceUpdates"
        choices={rights}
        label={uiStringsEt.deviceUpdatesLabel}
      />
      <SelectArrayInput
        source="sensorEventView"
        choices={limitedRights}
        label={uiStringsEt.sensorEventsLabel}
      />
      <SelectArrayInput
        source="sensorReportView"
        choices={limitedRights}
        label={uiStringsEt.sensorReportsLabel}
      />
    </SimpleForm>
  </Edit>
);

export default UserGroupEdit;
