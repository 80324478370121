import makeStore from '../makeStore';
import reducer from './reducer';
import { State } from './interface';

const initialState: State = { profileVersion: 0 };

const [ProfileProvider, useProfileDispatch, useProfile] = makeStore(
  reducer,
  initialState,
);

export { ProfileProvider, useProfileDispatch, useProfile };
