import React from 'react';
import { Layout, LayoutComponent } from 'react-admin';

import AdminAppBar from '../AdminAppBar';
import AdminMenu from '../AdminMenu';

const AdminLayout: LayoutComponent = (props) => (
  <Layout {...props} menu={AdminMenu} appBar={AdminAppBar} />
);

export default AdminLayout;
