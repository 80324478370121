// noinspection RequiredAttributes

import React, { useMemo } from 'react';
import {
  minLength,
  regex,
  required,
  SaveContextProvider,
  SimpleForm,
  TextInput,
  useGetIdentity,
} from 'react-admin';

import { UpdateUserProfile } from 'providers/dataProviderInterface';

import { useHandleSave } from './hooks';

// it's to fake translation function for IDE support
const t = (s: string) => s;

const validateUserUpdate = (values: UpdateUserProfile) => {
  const errors: Partial<UpdateUserProfile> = {};
  if (values.passwordRepeat !== values.password) {
    errors.password = t('app.myProfile.update.newPassword.error.label');
    errors.passwordRepeat = t('app.myProfile.update.newPassword.error.label');
  }
  return errors;
};

const ProfileEdit = (): React.ReactElement | null => {
  const { loaded, identity } = useGetIdentity();
  const [save, saving] = useHandleSave();

  const saveContext = useMemo(
    () => ({
      save,
      saving,
    }),
    [saving, save],
  );

  if (!loaded) {
    return null;
  }

  return (
    <SaveContextProvider value={saveContext}>
      <SimpleForm
        save={save}
        record={identity || {}}
        validate={validateUserUpdate}
      >
        <TextInput source="username" disabled />
        <TextInput source="email" disabled />
        <TextInput
          source="currentPassword"
          type="password"
          validate={required()}
        />
        <TextInput
          source="password"
          type="password"
          validate={[
            required(),
            regex(
              /(?=.*[a-z])/,
              t('app.user.create.fields.password.error.lowerCase'),
            ),
            regex(
              /(?=.*[A-Z])/,
              t('app.user.create.fields.password.error.capitalLetter'),
            ),
            regex(
              /(?=.*[0-9])/,
              t('app.user.create.fields.password.error.number'),
            ),
            minLength(8),
          ]}
        />
        <TextInput
          source="passwordRepeat"
          type="password"
          validate={[required()]}
        />
      </SimpleForm>
    </SaveContextProvider>
  );
};

export default ProfileEdit;
