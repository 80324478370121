import DefaultIcon from '@material-ui/icons/ViewList';
import React, { ComponentType, useMemo } from 'react';
import {
  getResources,
  MenuItemLink,
  MenuProps,
  useLogout,
  usePermissions,
} from 'react-admin';
import { useSelector } from 'react-redux';

import {
  clearanceRequirementForResource,
  passesClearance,
  resourceRoutes,
  resourceToPermissionMap,
} from 'utils';
import { Clearance, Permissions, Role } from 'utils/interfaces';

import AdminDownloadLogs from '../AdminDownloadLogs/AdminDownloadLogs';

const removeViewCounterparts = ['sensor', 'sensor-event'];

type IResource = {
  name: typeof resourceRoutes[number];
  icon: ComponentType;
  options: { label: string };
};

const AdminMenu: ComponentType<MenuProps> = () => {
  const { permissions } = usePermissions<Permissions>();
  const handleLogOut = useLogout();
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const resources: IResource[] = useSelector(getResources);
  const filteredResources = useMemo(
    () =>
      resources
        .filter((el) => !removeViewCounterparts.includes(el.name))
        .filter((el) => {
          const pass = passesClearance(
            permissions?.clearance,
            clearanceRequirementForResource[el.name],
          );
          const resourcePath = resourceToPermissionMap[el.name];
          if (
            resourcePath !== undefined &&
            permissions?.permissions[resourcePath]
          ) {
            return (
              pass && permissions?.permissions[resourcePath].includes(Role.READ)
            );
          }
          return pass;
        }),
    [permissions?.clearance, permissions?.permissions, resources],
  );

  if (
    permissions !== undefined &&
    permissions.clearance === Clearance.UNDEFINED
  ) {
    handleLogOut().then();
  }
  return (
    <div>
      {filteredResources.map((resource) => (
        <MenuItemLink
          key={resource.name}
          to={`/${resource.name}`}
          primaryText={
            (resource.options !== undefined && resource.options.label) ||
            resource.name
          }
          leftIcon={
            resource.icon !== undefined ? <resource.icon /> : <DefaultIcon />
          }
          sidebarIsOpen={open}
        />
      ))}
      {(permissions && permissions.clearance === Clearance.ROOT) ? (
        <AdminDownloadLogs />
      ) : null}
    </div>
  );
};

export default AdminMenu;
