import { Button } from '@material-ui/core';
import React, { useState } from 'react';
import {
  CreateProps,
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  ReferenceInput,
  required,
  TopToolbar,
  CreateActionsProps,
  useNotify,
} from 'react-admin';

import ReplaceModal from 'app/components/ReplaceModal';

import { uiStringsEt } from '../../../../i18n/ui.strings';

const SensorCreateActions = ({
  basePath,
}: CreateActionsProps): React.ReactElement => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <TopToolbar>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setIsModalOpen(true)}
      >
        {uiStringsEt.replaceButtonLabel}
      </Button>
      <ReplaceModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        basePath={basePath}
        isCreate
      />
    </TopToolbar>
  );
};

const SensorCreate = (props: CreateProps): React.ReactElement => {
  const notify = useNotify();
  const onFailure = () => {
    notify(`Could not create device, please check all fields and try again!`);
  };
  return (
    <Create {...props} onFailure={onFailure}>
      <SimpleForm>
        <TextInput
          source="deviceId"
          validate={[required()]}
          label={uiStringsEt.sensorIdLabel}
        />
        <TextInput source="simId" validate={[required()]} label="SIM ICCID " />
        <TextInput source="longDescription" label="Lisakirjeldus" />
      </SimpleForm>
    </Create>
  );
};

export default SensorCreate;
