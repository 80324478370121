export const uiStringsEt = {
  myProfile: 'Minu profiil',
  firmwareManagement: 'Püsivara sätted',
  uploadNewFirmWare: 'Lae üles uus püsivara',
  deleteFirmWare: 'Kustuta püsivara',
  relatedFiles: 'Seotud failid',
  dropYourBinFileHere: 'Lohista .bin fail siia',
  deleteButton: 'Kustuta',
  uploadButton: 'Lae üles',
  hardwareLabel: 'Riistvara',
  softwareLabel: 'Tarkvara',
  firmwareVersionLabel: 'Püsivara versioon',
  hardwareVersionLabel: 'Riistvara versioon',
  updateStatusLabel: 'Uuenduse olek',
  protocolVersionLabel: 'Protokolli versioon',
  softwareVersionLabel: 'Tarkvara versioon',
  fileLabel: 'Fail',
  sensorsLabel: 'Turvaelemendid',
  remoteControllersLabel: 'Andurite haldus',
  doorControllerModelsLabel: 'Süsteemid & kontrollerid',
  doorControllerModelLabel: 'Läbipääsusüsteemide kontrolleri tüüp',
  doorModelsLabel: 'Süsteemid & kontrollerid',
  clientsLabel: 'Lõpp-kliendid',
  gatewaysLabel: 'Läbipääsusüsteemid',
  deviceUpdatesLabel: 'Seadmete uuendused',
  sensorEventsLabel: 'Andurite sündmused',
  sensorReportsLabel: 'Andurite raportid',
  controllerEventsLabel: 'Kontrollerite sündmused',
  usersLabel: 'Kasutajate haldus',
  userGroupsLabel: 'Kasutajagrupid',
  sensorLogsLabel: 'Andurite logid',
  serverLogsLabel: 'Serveri logid',
  searchName: 'Otsi nime',
  searchDeviceId: 'Otsi id järgi',
  searchDoorModel: 'Otsi läbipääsusüsteemi tüüpi',
  searchGateway: 'Otsi läbipääsusüsteemi',
  searchClient: 'Otsi klienti',
  searchStatus: 'Otsi olekut',
  clientNameLabel: 'Lõpp-klient',
  resellerNameLabel: 'Edasimüüja',
  gatewayNameLabel: 'Läbipääsusüsteem',
  doorModelLabel: 'Läbipääsusüsteemi tüüp',
  sensorNameLabel: 'Andur',
  lastSensorEventLabel: 'Viimane sündmus',
  batteryLevelLabel: 'Aku laetus',
  cyclesToMaintenanceLabel: 'Tsükleid hoolduseni',
  sensorIdLabel: 'Anduri ID',
  cyclesLabel: 'Tsükleid',
  statusLabel: 'Staatus',
  maintenanceCycleLabel: 'Hooldustsükkel',
  editButtonLabel: 'Muuda',
  resetButtonLabel: 'Lähtesta',
  replaceButtonLabel: 'Asenda',
  sensorTypeLabel: 'Anduri tüüp',
  sensorStatusLabel: 'Anduri olek',
  lastCommandLabel: 'Viimane käsk',
  doorStatusLabel: 'Läbipääsusüsteemi olek',
  walkDoorStatus: 'Käidava süsteemi olek',
  sensor1Label: 'Andur 1',
  sensor2Label: 'Andur 2',
  controlButtonLabel: 'Kontrolli',
  modelNameLabel: 'Mudel',
  modelNoLabel: 'Mudeli number',
  searchLabel: 'Otsi',
  modelIdLabel: 'Mudeli ID',
  siteAddressLabel: 'Aadress',
  eventLabel: 'Sündmus',
  timeLabel: 'Aeg',
  timeStartLabel: 'Alates',
  timeEndLabel: 'Kuni',
  voltageLabel: 'Pinge',
  flagsLabel: 'Märked',
  controllerNameLabel: 'Kontroller',
  usernameLabel: 'Kasutajanimi',
  enabledLabel: 'Aktiivne',
  userGroupLabel: 'Kasutajagrupp',
  clearanceLabel: 'Ligipääs',
  passwordLabel: 'Salasõna',
  passwordRepeatLabel: 'Salasõna kordus',
  changeLabel: 'Muutus',
  reportTimeLabel: 'Aeg',
  levelLabel: 'Level',
  metaLabel: 'Meta',
  updateSoftwareLink: 'Uuenda tarkvara',
  downloadLogLink: 'Laadi alla logi',
};

export const uiStringsEn = {
  myProfile: 'My Profile',
  firmwareManagement: 'Firmware Management',
  uploadNewFirmWare: 'Upload new firmware',
  deleteFirmWare: 'Delete firmware',
  relatedFiles: 'Related files',
  dropYourBinFileHere: 'Drop your .bin file here',
  deleteButton: 'Delete',
  uploadButton: 'Upload',
  hardwareLabel: 'Hardware',
  softwareLabel: 'Software',
  firmwareVersionLabel: 'Firmware version',
  hardwareVersionLabel: 'Hardware version',
  updateStatusLabel: 'Update status',
  protocolVersionLabel: 'Protocol version',
  softwareVersionLabel: 'Software version',
  fileLabel: 'File',
  sensorsLabel: 'Sensors',
  remoteControllersLabel: 'Remote Controllers',
  doorControllerModelsLabel: 'Door Controller Models',
  doorControllerModelLabel: 'Door Controller Model',
  doorModelsLabel: 'Door Models',
  clientsLabel: 'Clients',
  gatewaysLabel: 'Gateways',
  deviceUpdatesLabel: 'Device updates',
  sensorEventsLabel: 'Sensor Events',
  sensorReportsLabel: 'Sensor Reports',
  controllerEventsLabel: 'Controller Events',
  usersLabel: 'Users',
  userGroupsLabel: 'User groups',
  sensorLogsLabel: 'Sensor Logs',
  serverLogsLabel: 'Server Logs',
  searchName: 'Search name',
  searchDeviceId: 'Search device id',
  searchDoorModel: 'Search door model',
  searchGateway: 'Search gateway',
  searchClient: 'Search client',
  searchStatus: 'Search status',
  clientNameLabel: 'Client name',
  resellerNameLabel: 'Reseller',
  gatewayNameLabel: 'Gateway',
  doorModelLabel: 'Door model',
  sensorNameLabel: 'Sensor Name',
  lastSensorEventLabel: 'Last event',
  batteryLevelLabel: 'Battery Level',
  cyclesToMaintenanceLabel: 'Cycles to maintenance',
  sensorIdLabel: 'Sensor ID',
  cyclesLabel: 'Cycles',
  statusLabel: 'Status',
  maintenanceCycleLabel: 'Maintenance cycle',
  editButtonLabel: 'Edit',
  resetButtonLabel: 'Reset',
  replaceButtonLabel: 'Replace',
  sensorTypeLabel: 'Sensor Type',
  sensorStatusLabel: 'Sensor status',
  lastCommandLabel: 'Last command',
  doorStatusLabel: 'Door status',
  walkDoorStatus: 'Walk door status',
  sensor1Label: 'Sensor1',
  sensor2Label: 'Sensor2',
  controlButtonLabel: 'Control',
  modelNameLabel: 'Model Name',
  modelNoLabel: 'Model No.',
  searchLabel: 'Search',
  modelIdLabel: 'Model ID',
  siteAddressLabel: 'Site Address',
  eventLabel: 'Event',
  timeLabel: 'Time',
  timeStartLabel: 'Time start',
  timeEndLabel: 'Time end',
  voltageLabel: 'Voltage',
  flagsLabel: 'Flags',
  controllerNameLabel: 'Controller name',
  usernameLabel: 'Username',
  enabledLabel: 'Enabled',
  userGroupLabel: 'User group',
  clearanceLabel: 'Clearance',
  passwordLabel: 'Password',
  passwordRepeatLabel: 'Password repeat',
  changeLabel: 'Change',
  reportTimeLabel: 'Report Time',
  levelLabel: 'Level',
  metaLabel: 'Meta',
  updateSoftwareLink: 'Update software',
  downloadLogLink: 'Download log',
};
