import { QuerySortOperator } from '@nestjsx/crud-request/lib/types/request-query.types';
import React, { useState } from 'react';
import { Button, useTranslate } from 'react-admin';
import DownloadIcon from '@material-ui/icons/GetApp';
import { QueryFilter } from '@nestjsx/crud-request';

import BackendService from 'service/BackendService';

export type Props = {
  resource: string;
  sort: { field: string; order: QuerySortOperator };
  filter?: Record<string, string>;
  manualFilter?: QueryFilter;
};

const ListExportButton = (props: Props): React.ReactElement => {
  const t = useTranslate();
  const [csvSource, setCsvSource] = useState<string>();
  const [key, setKey] = useState(0);
  const handleClick = () => {
    setCsvSource(BackendService.getResourceCSV(props));
    setKey((prev) => prev + 1);
  };
  return (
    <>
      <div style={{ display: 'none' }}>
        <iframe key={key} title="download" src={csvSource} />
      </div>
      <Button label={t('ra.action.export')} onClick={handleClick}>
        <DownloadIcon />
      </Button>
    </>
  );
};

export default ListExportButton;
