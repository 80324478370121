import axios from 'axios';

import { Sensors } from '../app/types';
import { SensorEvent } from '../utils/interfaces';

export default class BackendApi {
  static async getSensors(): Promise<Sensors[]> {
    const { data } = await axios.get<Sensors[]>('/api/sensor');
    return data;
  }

  static async getEvents(filterQuery: string): Promise<SensorEvent> {
    const { data } = await axios.get(`/api/sensor-event/events?${filterQuery}`);
    return data;
  }
}
