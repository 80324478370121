import React, { ReactElement } from 'react';
import {
  List,
  ListProps,
  Datagrid,
  TextField,
  FilterProps,
  Filter,
  TextInput,
  EditButton,
  FunctionField,
  ReferenceField,
} from 'react-admin';

import { Permissions } from 'utils/interfaces';
import { useCanEdit } from 'app/hooks';

import { uiStringsEt } from '../../../../i18n/ui.strings';

const ClientFilter = (props: Partial<FilterProps>): ReactElement => (
  <Filter {...props}>
    <TextInput
      label={uiStringsEt.searchLabel}
      source="name||$starts"
      alwaysOn
    />
  </Filter>
);

const ClientList = ({
  permissions,
  ...props
}: ListProps & { permissions?: Permissions }): React.ReactElement => {
  const canEdit = useCanEdit('client', permissions);
  return (
    <List
      {...props}
      filters={<ClientFilter />}
      exporter={false}
      perPage={25}
      bulkActionButtons={false}
    >
      <Datagrid>
        <TextField source="name" label="Nimi" />
        <FunctionField
          source="resellers"
          label="Edasimüüja"
          render={(record: any) => {
            if (record !== undefined) {
              if (record.resellerId) {
                return (
                  <ReferenceField
                    source="resellerId"
                    reference="reseller"
                    label="Edasimüüja"
                  >
                    <TextField source="name" />
                  </ReferenceField>
                );
              }
              return (
                <span style={{ display: 'flex', flexDirection: 'column' }}>
                  <span>—</span>
                </span>
              );
            }
            return record;
          }}
        />
        <FunctionField
          source="gateways"
          label="Uksed"
          render={(record: any) => {
            if (record !== undefined) {
              return (
                <span style={{ display: 'flex', flexDirection: 'column' }}>
                  <span>—</span>
                </span>
              );
            }
            return record;
          }}
        />
        {canEdit && <EditButton label={uiStringsEt.editButtonLabel} />}
      </Datagrid>
    </List>
  );
};

export default ClientList;
