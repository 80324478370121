import { Button, TextField } from '@material-ui/core';
import React from 'react';
import {
  useLogin,
  useNotify,
  Notification,
  Login,
  useTranslate,
} from 'react-admin';

import { StyleForm } from './styles';

const LoginPage = (): React.ReactElement => {
  const t = useTranslate();
  const login = useLogin();
  const notify = useNotify();
  const submit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      username: { value: string };
      password: { value: string };
    };
    const username = target.username.value;
    const password = target.password.value;
    login({ username, password }).catch(() =>
      notify('Invalid email or password'),
    );
  };

  return (
    <Login>
      <StyleForm onSubmit={submit}>
        <div>
          <TextField name="username" label={t('ra.auth.username')} />
          <TextField
            name="password"
            type="password"
            label={t('ra.auth.password')}
          />
        </div>
        <Button variant="contained" type="submit" fullWidth color="primary">
          {t('ra.auth.sign_in')}
        </Button>
      </StyleForm>
      <Notification />
    </Login>
  );
};

export default LoginPage;
