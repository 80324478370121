import {
  Button,
  makeStyles,
  Modal,
  Paper,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
  DeleteButton,
  Edit,
  EditProps,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  TitleProps,
  Toolbar,
  useQuery,
  Record as RARecord,
  useRecordContext,
} from 'react-admin';
import { useLocation } from 'react-router-dom';
import { CondOperator } from '@nestjsx/crud-request';

import { uiStringsEt } from '../../../../i18n/ui.strings';
import BackendService from '../../../../service/BackendService';

const Title = ({ record }: TitleProps): React.ReactElement => (
  <>Andur - {record?.deviceId}</>
);

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

type SensorModelReportClientSiteProps = {
  id: number;
  cycles: number;
};

const useDeleteButton = (record?: RARecord): boolean => {
  const [disabled, setDisabled] = useState(true);
  const sensorEvent = useQuery({
    type: 'getList',
    resource: 'sensor-event-list-view',
    payload: {
      pagination: { page: 1, perPage: 1 },
      filter: { 'sensorId||$eq': record?.id },
    },
  });
  const sensorReport = useQuery({
    type: 'getList',
    resource: 'sensor-report',
    payload: {
      pagination: { page: 1, perPage: 1 },
      filter: { 'sensorId||$eq': record?.id },
    },
  });
  useEffect(() => {
    if (sensorReport !== undefined && sensorEvent !== undefined) {
      if ((sensorReport.total ?? 1) > 0 || (sensorEvent.total ?? 1) > 0) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }
  }, [sensorEvent, sensorReport]);
  return disabled;
};

const SensorToolbar: typeof Toolbar = (props) => {
  const { record } = props;
  return (
    <Toolbar {...props} classes={useStyles()}>
      <SaveButton />
      <DeleteButton mutationMode="pessimistic" />
    </Toolbar>
  );
};

const DownloadLogLink = (): React.ReactElement => {
  const record = useRecordContext();
  const location = useLocation();
  const [csvSource, setCsvSource] = useState<string>();
  const [key, setKey] = useState(0);
  const handleSensorEventClick = () => {
    setCsvSource(
      BackendService.getResourceCSV({
        resource: 'sensor-event-list-view',
        sort: {
          field: 'time',
          order: 'DESC',
        },
        manualFilter: {
          field: 'sensorId',
          operator: CondOperator.EQUALS,
          value: record.id,
        },
      }),
    );
    setKey((prev) => prev + 1);
  };
  return (
    <div>
      <span style={{ marginLeft: '48px' }}>
        <a href={`#${location.pathname}`} onClick={handleSensorEventClick}>
          {uiStringsEt.downloadLogLink}
        </a>
      </span>
      <div style={{ display: 'none' }}>
        <iframe key={key} title="download" src={csvSource} />
      </div>
    </div>
  );
};

const SensorManagementEdit = (props: EditProps): React.ReactElement => {
  const location = useLocation();
  return (
    <div>
      <Edit {...props} title={<Title />}>
        <SimpleForm toolbar={<SensorToolbar />}>
          <TextInput
            source="deviceId"
            validate={[required()]}
            label={uiStringsEt.sensorIdLabel}
          />
          <TextInput source="simId" validate={[required()]} label="SIM ICCID " />
          <TextInput source="longDescription" label="Lisakirjeldus" />
          <TextInput
            disabled
            source="hwVersion"
            label={uiStringsEt.hardwareVersionLabel}
          />
          <TextInput
            disabled
            source="fwVersion"
            label={uiStringsEt.softwareVersionLabel}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <span>
              <a href={`#${location.pathname}`}>
                {uiStringsEt.updateSoftwareLink}
              </a>
            </span>
            <DownloadLogLink />
          </div>
        </SimpleForm>
      </Edit>
    </div>
  );
};

export default SensorManagementEdit;
