import React from 'react';
import {
  CreateProps,
  Create,
  SimpleForm,
  TextInput,
  required,
  email,
  SelectInput,
  ReferenceInput,
  useGetIdentity,
  useGetOne,
} from 'react-admin';

import { uiStringsEt } from '../../../../i18n/ui.strings';
import { Clearance } from '../../../../utils/interfaces';

const ClientCreate = (props: CreateProps): React.ReactElement => {
  const { loaded, identity } = useGetIdentity();
  const userDataId = identity ? identity.id : '';
  const { data: user, loading, error } = useGetOne('user', userDataId);
  const userClearance = user ? user.clearance : null;

  return (
    <Create {...props} title={<>Lisa uus lõppklient</>}>
      <SimpleForm redirect="list">
        <TextInput label="Nimi" source="name" validate={[required()]} />
        <TextInput label="Registrinumber" source="registerNo" />
        <TextInput label="Aadress" source="address" validate={[required()]} />
        <TextInput
          label="Käibemaksukohuslase number"
          source="vatNo"
          validate={[required()]}
        />
        <TextInput
          label="Kontaktisik"
          source="contactName"
          validate={[required()]}
        />
        <TextInput
          label="Kontakt e-mail"
          source="contactEmail"
          validate={[required(), email()]}
        />
        {userClearance === Clearance.RESELLER ? null : (
          <ReferenceInput
            label="Edasimüüja valik"
            reference="reseller"
            source="resellerId"
            validate={[required()]}
          >
            <SelectInput source="name" />
          </ReferenceInput>
        )}
      </SimpleForm>
    </Create>
  );
};

export default ClientCreate;
