import React from 'react';
import {
  Datagrid,
  DateField,
  EditButton,
  Filter,
  FilterProps,
  FunctionField,
  List,
  ListProps,
  RadioButtonGroupInput,
  TextInput,
  useGetList,
} from 'react-admin';
import { makeStyles } from '@material-ui/core';

import { useCanEdit } from 'app/hooks';
import { Permissions } from 'utils/interfaces';

const useFilterStyles = makeStyles((theme) => ({
  search: {
    //  border: '1px solid red',
  },
  active: {
    marginTop: 0,
    marginBottom: 0,
    //  border: '1px solid red',
  },
}));

const TestFilter = (props: Partial<FilterProps>) => {
  const classes = useFilterStyles();

  return (
    <Filter {...props}>
      <TextInput
        label="Otsi"
        source="username||$starts"
        alwaysOn
        className={classes.search}
      />

      <RadioButtonGroupInput
        source="clearance||$in"
        alwaysOn
        className={classes.active}
        label=""
        choices={[
          { id: '', name: 'Näita kõik' },
          { id: 'ROOT', name: 'Ainult Admin' },
          { id: 'RESELLER', name: 'Ainult Edasimüüjad' },
          { id: 'CLIENT', name: 'Ainult Lõpp-kliendid' },
        ]}
      />
    </Filter>
  );
};

const UserList = ({
  permissions,
  ...props
}: ListProps & { permissions?: Permissions }): React.ReactElement => {
  const canEdit = useCanEdit('user', permissions);
  const { data } = useGetList('reseller');
  const resellers = Object.values(data);

  return (
    <List
      {...props}
      exporter={false}
      perPage={25}
      bulkActionButtons={false}
      filters={<TestFilter />}
    >
      <Datagrid>
        <FunctionField
          source="username"
          label="Kasutajanimi / email"
          render={(record: any) => {
            if (record !== undefined) {
              return (
                <span style={{ display: 'flex', flexDirection: 'column' }}>
                  <span>{record.username}</span>
                  <span style={{ color: '#999999' }}>{record.email}</span>
                </span>
              );
            }
            return record;
          }}
        />
        <FunctionField
          source="clearance"
          label="TASE"
          render={(record: any) => {
            if (record !== undefined) {
              switch (record.clearance) {
                case 'ADMIN': {
                  return (
                    <span style={{ display: 'flex', flexDirection: 'column' }}>
                      <span>ADMIN</span>
                    </span>
                  );
                }
                case 'ROOT': {
                  return (
                    <span style={{ display: 'flex', flexDirection: 'column' }}>
                      <span>Admin</span>
                    </span>
                  );
                }
                case 'RESELLER': {
                  if (record.resellers.length > 0) {
                    return (
                      <span
                        style={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <span>{`Edasimüüja: ${record.resellers[0].name}`}</span>
                      </span>
                    );
                  }
                  return (
                    <span style={{ display: 'flex', flexDirection: 'column' }}>
                      <span>Edasimüüja: —</span>
                    </span>
                  );
                }
                case 'CLIENT': {
                  if (record.clients.length > 0) {
                    const resellerExists = resellers.find(
                      (reseller) =>
                        reseller.id === record.clients[0].resellerId,
                    );
                    return (
                      <span
                        style={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <span>{`Klient: ${record.clients[0].name}`}</span>
                        <span style={{ color: '#999999' }}>{`Edasimüüja: ${
                          resellerExists ? resellerExists.name : '—'
                        }`}</span>
                      </span>
                    );
                  }
                  return (
                    <span style={{ display: 'flex', flexDirection: 'column' }}>
                      <span>Klient: —</span>
                      <span style={{ color: '#999999' }}>Edasimüüja: —</span>
                    </span>
                  );
                }
                default:
                  return (
                    <span style={{ display: 'flex', flexDirection: 'column' }}>
                      <span>-</span>
                    </span>
                  );
              }
            }
            return record;
          }}
        />
        <DateField label="Lisamise k.p" source="createdAt" />
        {canEdit && <EditButton label="Näita rohkem" />}
      </Datagrid>
    </List>
  );
};

export default UserList;
