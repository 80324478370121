import { RequestQueryBuilder } from '@nestjsx/crud-request';
import axios from 'axios';

import { Props } from 'app/components/ListExportButton/ListExportButton';
import { SensorEvent } from 'utils/interfaces';

import BackendApi from '../api/backend.api';
import { Sensors } from '../app/types';
import { composeFilter } from '../providers/dataProvider';
import { defaultDateRange } from '../utils';

export default class BackendService {
  static async getSensors(): Promise<Sensors[]> {
    return BackendApi.getSensors();
  }

  static async getSensorsEvents({
    selectedSensors,
    selectedInterval,
    endTime,
    startTime,
  }: {
    startTime: Date | null;
    endTime: Date | null;
    selectedSensors: string[];
    selectedInterval: string;
  }): Promise<SensorEvent> {
    const mapSelectedSensors = (s: string): string => `&selectedSensors[]=${s}`;

    const query = encodeURI(
      `startTime=${(
        startTime ?? defaultDateRange.defaultStartTime
      ).toISOString()}&endTime=${(
        endTime ?? defaultDateRange.defaultEndTime
      ).toISOString()}${selectedSensors
        .map(mapSelectedSensors)
        .join('')}&selectedInterval=${selectedInterval}`,
    );

    return BackendApi.getEvents(query);
  }

  static getResourceCSV({
    resource,
    sort: { field, order },
    filter,
    manualFilter,
  }: Props): string {
    const query = RequestQueryBuilder.create({
      sort: [{ field, order }],
      filter: manualFilter || composeFilter(filter),
    }).query();
    return `api/${resource}/download/csv?${query}`;
  }

  static async resetSensor(id: number): Promise<unknown> {
    return axios.post('api/sensor-list-view/reset', { id });
  }

  static async resetController(id: number, cycles: number): Promise<unknown> {
    return axios.post('api/remote-controller-list-view/reset', { id, cycles });
  }

  static async replaceSensor(params: {
    editSensorId?: string;
    selectedItemId: string | null;
    checked: boolean;
    deviceId: string;
  }): Promise<string> {
    const { data } = await axios.post<string>('api/sensor/replace', params);
    return data;
  }

  static async controllerCommands(params: {
    deviceId: string;
    command: number;
    rssi?: number;
    lastCommand: string;
    walkDoorStatus?: boolean;
    sensor1Status?: boolean;
    sensor2Status?: boolean;
    controllerId?: string;
  }): Promise<string> {
    const { data } = await axios.post<string>('api/controller-command/command', params);
    return data;
  }

  static async resetMaintenance(params: {
    deviceId: string;
    gatewayId: string | number;
  }): Promise<string> {
    const { data } = await axios.put<string>(
      `api/gateway/reset/maintenance/${params.gatewayId}`,
      params,
    );
    return data;
  }

  static async resetSpring(params: {
    deviceId: string;
    gatewayId: string | number;
  }): Promise<string> {
    const { data } = await axios.put<string>(
      `api/gateway/reset/spring/${params.gatewayId}`,
      params,
    );
    return data;
  }

  static async firmwareUpdate(params: { deviceId: any }): Promise<string> {
    const { data } = await axios.post<string>('api/device-updates/update-senors-database', params);
    return data;
  }

  static async generateResellerToken(resellerId: string): Promise<string> {
    const { data } = await axios.post(
      `api/reseller/generate-token/${resellerId}`,
    );

    return data.token;
  }

  // static async uploadNewFirmware(hardware:string, software:string, binFile, binFileName:string): Promise<unknown> {
  //   // const result = await binFile[0].arrayBuffer();
  //   // const finalResult = new Uint8Array(Buffer.from(binFile));
  //   // console.log(new Uint8Array(Buffer.from(binFile)));
  //   console.log(binFile)
  //   const { data } = await axios.post('api/file-upload/new', {hardware, software, binFile, binFileName});
  //   return data;
  // }
}
