import { Button } from '@material-ui/core';
import React, { useState } from 'react';
import {
  CreateProps,
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  ReferenceInput,
  required,
  TopToolbar,
  CreateActionsProps,
  useNotify,
} from 'react-admin';

import ReplaceModal from 'app/components/ReplaceModal';

import { uiStringsEt } from '../../../../i18n/ui.strings';

const SensorCreateActions = ({
  basePath,
}: CreateActionsProps): React.ReactElement => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <TopToolbar>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setIsModalOpen(true)}
      >
        {uiStringsEt.replaceButtonLabel}
      </Button>
      <ReplaceModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        basePath={basePath}
        isCreate
      />
    </TopToolbar>
  );
};

const SensorCreate = (props: CreateProps): React.ReactElement => {
  const notify = useNotify();
  const onFailure = () => {
    notify(`Could not create device, please check all fields and try again!`);
  };
  return (
    <Create {...props} onFailure={onFailure} actions={<SensorCreateActions />}>
      <SimpleForm>
        <TextInput
          source="deviceId"
          validate={[required()]}
          label={uiStringsEt.sensorIdLabel}
        />
        <TextInput
          source="directoId"
          validate={[required()]}
          label="Directo id"
        />
        <TextInput
          source="shortDescription"
          validate={[required()]}
          label={uiStringsEt.sensorNameLabel}
        />
        <TextInput source="longDescription" label="Sensor description" />
        <SelectInput
          label={uiStringsEt.sensorTypeLabel}
          source="type"
          validate={[required()]}
          choices={[
            { id: 'WiredController', name: 'Wired controller' },
            { id: 'WirelessSensor', name: 'Wireless sensor' },
          ]}
        />
        <ReferenceInput
          reference="door-controller-model"
          source="doorcontrollermodelid"
          label={uiStringsEt.doorControllerModelLabel}
          allowEmpty
          emptyValue={null}
        >
          <SelectInput source="name" />
        </ReferenceInput>
        <ReferenceInput
          reference="door-model"
          source="doorModelId"
          label={uiStringsEt.doorModelLabel}
        >
          <SelectInput source="name" validate={[required()]} />
        </ReferenceInput>
        <ReferenceInput reference="site" source="siteId" label={uiStringsEt.gatewayNameLabel}>
          <SelectInput source="name" validate={[required()]} />
        </ReferenceInput>
        <SelectInput
          label={uiStringsEt.sensorStatusLabel}
          source="status"
          choices={[
            { id: 'active', name: 'active' },
            { id: 'faulty', name: 'faulty' },
            { id: 'maintenance', name: 'maintenance' },
            { id: 'deactivated', name: 'deactivated' },
          ]}
        />
      </SimpleForm>
    </Create>
  );
};

export default SensorCreate;
