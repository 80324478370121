import React from 'react';
import {
  Datagrid,
  DateField,
  Filter,
  FilterProps,
  FunctionField,
  List,
  ListProps,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  Pagination,
  PaginationProps,
  DateInput,
} from 'react-admin';

import AdminListActions from 'app/components/AdminListActions';
import { useCanEdit } from 'app/hooks';

import { uiStringsEt } from '../../../../i18n/ui.strings';

const ListPagination = (props: PaginationProps): React.ReactElement => (
  <Pagination {...props} rowsPerPageOptions={[10, 25, 50, 100]} />
);
const ListFilter = (props: Partial<FilterProps>) => (
  <Filter {...props}>
    <ReferenceInput
      label={uiStringsEt.clientNameLabel}
      source="clientId||$eq"
      reference="client"
      allowEmpty
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      label={uiStringsEt.gatewayNameLabel}
      source="siteId||$eq"
      reference="gateway"
      allowEmpty
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      label={uiStringsEt.sensorNameLabel}
      source="sensorId||$eq"
      reference="sensor"
      allowEmpty
    >
      <SelectInput optionText="shortDescription" />
    </ReferenceInput>
    <ReferenceInput
      label={uiStringsEt.doorModelLabel}
      source="doorModelId||$eq"
      reference="door-model"
      allowEmpty
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <DateInput label={uiStringsEt.timeStartLabel} source="time||$gte" />
    <DateInput label={uiStringsEt.timeEndLabel} source="time||$lte" />
  </Filter>
);

type SensorEventList = {
  id: string;
  type: 'DOOR_CYCLE' | 'DOOR_OPENED' | 'DOOR_CLOSED';
  time: Date;
};

const SensorEventListView = ({
  permissions,
  ...props
}: ListProps): React.ReactElement => {
  const canEditClient = useCanEdit('client', permissions);
  const canEditSite = useCanEdit('site', permissions);
  const canEditSensor = useCanEdit('sensor', permissions);
  return (
    <List
      {...props}
      filters={<ListFilter />}
      actions={<AdminListActions />}
      pagination={<ListPagination />}
      sort={{ field: 'time', order: 'DESC' }}
    >
      <Datagrid rowClick="edit">
        <ReferenceField
          source="clientId"
          reference="client"
          label={uiStringsEt.clientNameLabel}
          link={canEditClient}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          source="siteId"
          reference="gateway"
          label={uiStringsEt.siteAddressLabel}
          link={canEditSite}
        >
          <TextField source="address" />
        </ReferenceField>
        <ReferenceField
          source="sensorId"
          reference="sensor-list-view"
          label={uiStringsEt.sensorNameLabel}
          link={canEditSensor}
        >
          <TextField source="shortDescription" />
        </ReferenceField>
        <DateField label={uiStringsEt.timeLabel} source="time" showTime locales={['et', 'en']} />
        <FunctionField<SensorEventList>
          source="type"
          label={uiStringsEt.eventLabel}
          render={(record) => {
            if (record !== undefined) {
              switch (record.type) {
                case 'DOOR_CLOSED':
                  return 'Suletud';
                case 'DOOR_OPENED':
                default:
                  return 'Avatud';
              }
            }
            return record;
          }}
        />
      </Datagrid>
    </List>
  );
};

export default SensorEventListView;
