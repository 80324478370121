import React from 'react';
import { MenuItemLink, UserMenu } from 'react-admin';
import SettingsIcon from '@material-ui/icons/Settings';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import { useProfile } from '@store/profile/store';

import { uiStringsEt } from '../../../i18n/ui.strings';

const AdminUserMenu = (props: unknown): React.ReactElement => {
  const { profileVersion } = useProfile();
  return (
    <UserMenu {...props} key={profileVersion}>
      <MenuItemLink
        sidebarIsOpen
        to="/my-profile"
        primaryText={uiStringsEt.myProfile}
        leftIcon={<SettingsIcon />}
      />
      <MenuItemLink
        sidebarIsOpen
        to="/file-upload"
        primaryText={uiStringsEt.firmwareManagement}
        leftIcon={<CloudUploadIcon />}
      />
    </UserMenu>
  );
};

export default AdminUserMenu;
