import { Dispatch, Reducer, useEffect, useReducer } from 'react';

type State = { value: string; error: boolean; touched: boolean };
type Actions = string | undefined;
const reducer: Reducer<State, Actions> = (prevState, value) => {
  if (value === undefined) {
    return {
      value: prevState.value,
      touched: true,
      error: prevState.value === '',
    };
  }
  return {
    value,
    touched: true,
    error: value === '',
  };
};

export const useResetDeviceId = (
  sensorId: string,
): [State, Dispatch<Actions>] => {
  const [result, dispatch] = useReducer(reducer, {
    value: sensorId,
    error: false,
    touched: false,
  });

  useEffect(() => {
    dispatch(sensorId);
  }, [sensorId]);

  return [result, dispatch];
};
export const useSelectedItemId = (): [State, Dispatch<Actions>] =>
  useReducer(reducer, {
    value: '',
    error: false,
    touched: false,
  });
