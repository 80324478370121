import React from 'react';
import {
  EditProps,
  Edit,
  TextInput,
  NumberInput,
  SimpleForm,
  minValue,
  TitleProps,
} from 'react-admin';

import { uiStringsEt } from '../../../../i18n/ui.strings';

const Title = ({ record }: TitleProps): React.ReactElement => (
  <>Door Model - {record?.name}</>
);

const DoorModelEdit = (props: EditProps): React.ReactElement => (
  <Edit {...props} title={<Title />}>
    <SimpleForm>
      <TextInput label={uiStringsEt.modelNameLabel} source="name" />
      <NumberInput
        label={uiStringsEt.maintenanceCycleLabel}
        source="maintenanceCycle"
        validate={[minValue(0)]}
      />
    </SimpleForm>
  </Edit>
);

export default DoorModelEdit;
