import { useMemo } from 'react';

import { Clearance, Role, GroupRoles, Permissions } from 'utils/interfaces';

import { ClearanceOrderMap } from '../../utils';

export const useCanEdit = (
  group: keyof GroupRoles,
  permissions?: Permissions,
): boolean =>
  useMemo(
    () =>
      !!(
        permissions?.clearance === Clearance.ROOT ||
        permissions?.permissions[group].includes(Role.UPDATE)
      ),
    [group, permissions?.clearance, permissions?.permissions],
  );
export const useCanOpenClient = (permissions?: Permissions): boolean => {
  const canEditClient = useCanEdit('client', permissions);
  return useMemo(
    () =>
      canEditClient &&
      permissions?.clearance !== undefined &&
      ClearanceOrderMap[permissions.clearance as Clearance] >=
        ClearanceOrderMap[Clearance.CLIENT],
    [canEditClient, permissions?.clearance],
  );
};
