import React, { ReactElement } from 'react';
import {
  List,
  ListProps,
  Datagrid,
  TextField,
  FilterProps,
  Filter,
  TextInput,
  EditButton,
  FunctionField,
} from 'react-admin';

import { Permissions } from 'utils/interfaces';
import { useCanEdit } from 'app/hooks';

import { uiStringsEt } from '../../../../i18n/ui.strings';

const ResellerFilter = (props: Partial<FilterProps>): ReactElement => (
  <Filter {...props}>
    <TextInput
      label={uiStringsEt.searchLabel}
      source="name||$starts"
      alwaysOn
    />
  </Filter>
);

const ResellerList = ({
  permissions,
  ...props
}: ListProps & { permissions?: Permissions }): React.ReactElement => {
  const canEdit = useCanEdit('reseller', permissions);
  return (
    <List
      {...props}
      exporter={false}
      perPage={25}
      bulkActionButtons={false}
      filters={<ResellerFilter />}
    >
      <Datagrid>
        <FunctionField
          source="name"
          label="Nimi"
          render={(record: any) => {
            if (record !== undefined) {
              return (
                <span style={{ display: 'flex', flexDirection: 'column' }}>
                  <span>{record.name}</span>
                  <span style={{ color: '#999999' }}>{record.registerNo}</span>
                </span>
              );
            }
            return record;
          }}
        />
        <FunctionField
          source="name"
          label="# andureid / # aktiivseid"
          render={(record: any) => {
            if (record !== undefined) {
              if (record.sensors) {
                return (
                  <span style={{ display: 'flex', flexDirection: 'column' }}>
                    <span>{`${record.sensors.length} / ${
                      record.sensors.filter(
                        (sensor: any) => sensor.activateDate,
                      ).length
                    }`}</span>
                  </span>
                );
              }
              return '—';
            }
            return record;
          }}
        />
        <FunctionField
          source="name"
          label="# kliente"
          render={(record: any) => {
            if (record !== undefined) {
              if (record.clientCount) {
                return (
                  <span style={{ display: 'flex', flexDirection: 'column' }}>
                    <span>{record.clientCount}</span>
                  </span>
                );
              }
              return (
                <span style={{ display: 'flex', flexDirection: 'column' }}>
                  <span>0</span>
                </span>
              );
            }
            return record;
          }}
        />
        <FunctionField
          source="name"
          label="# kasutajaid"
          render={(record: any) => {
            if (record !== undefined) {
              if (record.resellerUserCount) {
                return (
                  <span style={{ display: 'flex', flexDirection: 'column' }}>
                    <span>{record.resellerUserCount}</span>
                  </span>
                );
              }
              return (
                <span style={{ display: 'flex', flexDirection: 'column' }}>
                  <span>0</span>
                </span>
              );
            }
            return record;
          }}
        />
        {canEdit && <EditButton label={uiStringsEt.editButtonLabel} />}
      </Datagrid>
    </List>
  );
};

export default ResellerList;
