import { JwtPayload } from 'jwt-decode';

export interface SensorEvent {
  labels: Date[];
  dataset: { name: string; device: string; data: number[] }[];
}

export enum Role {
  CREATE = 'CREATE',
  READ = 'READ',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export interface UserGroup {
  id: string;
  name: string;
  sensor: Role[];
  doorControllerModel: Role[];
  doorModel: Role[];
  client: Role[];
  reseller: Role[];
  site: Role[];
  gateway: Role[];
  deviceUpdates: Role[];
  user: Role[];
  userGroup: Role[];
  sensorEventView: Role[];
  sensorReportView: Role[];
  RemoteControllerEvent: Role[];
  sensorLog: Role[];
  serverLog: Role[];
}
export type GroupRoles = Omit<UserGroup, 'id' | 'name'>;

export enum Clearance {
  SUPER = 'SUPER',
  ROOT = 'ROOT',
  CLIENT = 'CLIENT',
  RESELLER = 'RESELLER',
  SITE = 'SITE',
  UNDEFINED = 'UNDEFINED',
}
export enum ClearanceOrder {
  UNDEFINED,
  SITE,
  CLIENT,
  RESELLER,
  ROOT,
  SUPER,
}

export interface ApiJwtPayload extends JwtPayload {
  username: string;
  email: string;
  clearance: Clearance;
  permissions: UserGroup;
}

export type Permissions = {
  clearance: Clearance;
  permissions: UserGroup;
};
