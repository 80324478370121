import { QuerySortOperator } from '@nestjsx/crud-request/lib/types/request-query.types';
import React, { cloneElement } from 'react';
import {
  CreateButton,
  ListActionsProps,
  sanitizeListRestProps,
  SortPayload,
  TopToolbar,
  useListContext,
} from 'react-admin';

import ListExportButton from '../ListExportButton';

const AdminListActions = ({
  className,
  filters,
  ...rest
}: ListActionsProps): React.ReactElement => {
  const {
    currentSort: { order, field },
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    showFilter,
  } = useListContext();
  const isQuerySortOperator = (
    sort: SortPayload['order'],
  ): sort is QuerySortOperator => sort === 'ASC' || sort === 'DESC';
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {hasCreate && <CreateButton basePath={basePath} />}
      <ListExportButton
        resource={resource}
        filter={filterValues}
        sort={
          isQuerySortOperator(order)
            ? { field, order }
            : { field, order: 'ASC' }
        }
      />
    </TopToolbar>
  );
};

export default AdminListActions;
