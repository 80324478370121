import { Translate } from 'react-admin';

import { TimeUnits } from '../app/hooks/useChart/useChart';
import { Clearance, ClearanceOrder, GroupRoles, Role } from './interfaces';

const defaultStartTime = new Date();
const defaultEndTime = new Date();
defaultStartTime.setHours(0, 0, 0, 0);
defaultEndTime.setHours(0, 0, 0, 0);
defaultEndTime.setDate(defaultEndTime.getDate() + 1);
defaultEndTime.setTime(defaultEndTime.getTime() - 1);

export const defaultDateRange = { defaultStartTime, defaultEndTime };

export type DateIntervals = {
  label: string;
  timeUnit: TimeUnits;
  unit: string;
};
export const dateIntervals = (t: Translate): DateIntervals[] => [
  {
    label: t('app.eventsChart.interval.oneHour.label'),
    timeUnit: 'hour',
    unit: '1 hour',
  },
  {
    label: t('app.eventsChart.interval.oneDay.label'),
    timeUnit: 'day',
    unit: '1 day',
  },
  {
    label: t('app.eventsChart.interval.oneWeek.label'),
    timeUnit: 'week',
    unit: '1 week',
  },
  {
    label: t('app.eventsChart.interval.oneMonth.label'),
    timeUnit: 'month',
    unit: '1 month',
  },
  {
    label: t('app.eventsChart.interval.oneYear.label'),
    timeUnit: 'year',
    unit: '1 year',
  },
];

export const resourceRoutes = [
  'remote-controller-list-view',
  'sensor-list-view',
  'sensor-list',
  'sensor-list/gateway/valid',
  'sensor-list/reseller/valid',
  'door-controller-model',
  'door-model',
  'client',
  'site',
  'gateway',
  'device-updates',
  'sensor-event-list-view',
  'sensor-report',
  'remote-controller-event-list-view',
  'user',
  'user-group',
  'sensor',
  'sensor-event',
  'sensor-log',
  'server-log',
  'reseller',
] as const;
export const clearanceRequirementForResource: Record<
  typeof resourceRoutes[number],
  keyof typeof Clearance
> = {
  'remote-controller-list-view': Clearance.SITE,
  'sensor-list-view': Clearance.SUPER,
  sensor: Clearance.RESELLER,
  'sensor-list': Clearance.RESELLER,
  'sensor-list/gateway/valid': Clearance.SUPER,
  'sensor-list/reseller/valid': Clearance.SUPER,
  'door-controller-model': Clearance.SUPER,
  'door-model': Clearance.ROOT,
  client: Clearance.RESELLER,
  site: Clearance.SITE,
  gateway: Clearance.SITE,
  'device-updates': Clearance.SITE,
  'sensor-event-list-view': Clearance.SUPER,
  'sensor-event': Clearance.SITE,
  'sensor-report': Clearance.SITE,
  'remote-controller-event-list-view': Clearance.SITE,
  user: Clearance.SITE,
  'user-group': Clearance.ROOT,
  'sensor-log': Clearance.SUPER,
  'server-log': Clearance.SUPER,
  reseller: Clearance.ROOT,
} as const;
export const resourceToPermissionMap: {
  'sensor-log': 'sensorLog';
  'server-log': 'serverLog';
  'door-controller-model': 'doorControllerModel';
  'remote-controller-list-view': 'sensor';
  'door-model': 'doorModel';
  'sensor-event': 'sensorEventView';
  'user-group': 'userGroup';
  'sensor-report': 'sensorReportView';
  'sensor-list-view': 'sensor';
  'sensor-list': 'sensor';
  'sensor-list/gateway/valid': 'sensorReportView';
  'sensor-list/reseller/valid': 'sensorReportView';
  site: 'site';
  'device-updates': 'deviceUpdates';
  client: 'client';
  sensor: 'sensor';
  user: 'user';
  gateway: 'gateway';
  'sensor-event-list-view': 'sensorEventView';
  'remote-controller-event-list-view': 'RemoteControllerEvent';
  reseller: 'client';
} = {
  'remote-controller-list-view': 'sensor',
  'sensor-list-view': 'sensor',
  'sensor-list': 'sensor',
  'sensor-list/gateway/valid': 'sensorReportView',
  'sensor-list/reseller/valid': 'sensorReportView',
  sensor: 'sensor',
  'door-controller-model': 'doorControllerModel',
  'door-model': 'doorModel',
  client: 'client',
  site: 'site',
  gateway: 'gateway',
  'device-updates': 'deviceUpdates',
  user: 'user',
  'sensor-event-list-view': 'sensorEventView',
  'sensor-event': 'sensorEventView',
  'sensor-report': 'sensorReportView',
  'remote-controller-event-list-view': 'RemoteControllerEvent',
  'user-group': 'userGroup',
  'sensor-log': 'sensorLog',
  'server-log': 'serverLog',
  reseller: 'client',
} as const;

export const ClearanceOrderMap = {
  [Clearance.SUPER]: ClearanceOrder.SUPER,
  [Clearance.ROOT]: ClearanceOrder.ROOT,
  [Clearance.CLIENT]: ClearanceOrder.CLIENT,
  [Clearance.RESELLER]: ClearanceOrder.RESELLER,
  [Clearance.SITE]: ClearanceOrder.SITE,
  [Clearance.UNDEFINED]: ClearanceOrder.UNDEFINED,
};

export const canDo = (
  resource: keyof typeof clearanceRequirementForResource,
  clearance?: Clearance,
  roles?: Role[],
): Record<keyof typeof Role, boolean> => {
  if (clearance === Clearance.ROOT) {
    return {
      [Role.UPDATE]: true,
      [Role.CREATE]: true,
      [Role.READ]: true,
      [Role.DELETE]: true,
    };
  }
  const actions: Record<keyof typeof Role, boolean> = {
    [Role.UPDATE]: false,
    [Role.CREATE]: false,
    [Role.READ]: false,
    [Role.DELETE]: false,
  };
  if (clearance === undefined || clearance === Clearance.UNDEFINED) {
    return actions;
  }
  const clear = ClearanceOrderMap[clearance];
  const required = ClearanceOrderMap[clearanceRequirementForResource[resource]];
  if (clear < required) {
    return actions;
  }
  roles?.forEach((role) => {
    actions[role] = true;
  });

  return actions;
};

export const passesClearance = (
  clearance?: Clearance,
  requiredClearance?: keyof typeof Clearance,
): boolean =>
  clearance !== undefined &&
  requiredClearance !== undefined &&
  ClearanceOrderMap[clearance] >= ClearanceOrderMap[requiredClearance];
