import React from 'react';
import { Datagrid, EditButton, List, ListProps, TextField } from 'react-admin';
import styled from 'styled-components';

import { useCanEdit } from 'app/hooks';
import { Permissions } from 'utils/interfaces';

import { uiStringsEt } from '../../../../i18n/ui.strings';

const ListFieldLayout = styled.ul`
  list-style-type: none;
  padding-inline-start: 0;
`;

const ListField = ({
  record,
  source,
}: {
  record?: IUserGroup;
  source: keyof Omit<IUserGroup, 'id' | 'name'>;
  label: string;
}) => (
  <ListFieldLayout>
    {record?.[source].map((item) => (
      <li key={item}>{item}</li>
    ))}
  </ListFieldLayout>
);

type IUserGroup = {
  id: string;
  name: string;
  sensor: string[];
  doorModel: string[];
  doorControllerModel: string[];
  client: string[];
  reseller: string[];
  site: string[];
  gateway: string[];
  controllerEvents: string[];
  user: string[];
  userGroup: string[];
  deviceUpdates: string[];
  sensorEventView: string[];
  sensorReportView: string[];
};

const UserGroupList = ({
  permissions,
  ...props
}: ListProps & { permissions?: Permissions }): React.ReactElement => {
  const canEdit = useCanEdit('userGroup', permissions);
  return (
    <List {...props} exporter={false}>
      <Datagrid>
        <TextField source="name" label={uiStringsEt.userGroupLabel} />
        <ListField source="sensor" label={uiStringsEt.sensorNameLabel} />
        <ListField
          source="doorControllerModel"
          label={uiStringsEt.doorControllerModelLabel}
        />
        <ListField source="doorModel" label={uiStringsEt.doorModelLabel} />
        <ListField source="client" label={uiStringsEt.clientNameLabel} />
        <ListField source="reseller" label={uiStringsEt.resellerNameLabel} />
        <ListField source="site" label={uiStringsEt.gatewayNameLabel} />
        <ListField
          source="controllerEvents"
          label={uiStringsEt.controllerEventsLabel}
        />
        <ListField source="user" label={uiStringsEt.usersLabel} />
        <ListField source="userGroup" label={uiStringsEt.userGroupsLabel} />
        <ListField
          source="deviceUpdates"
          label={uiStringsEt.deviceUpdatesLabel}
        />
        <ListField
          source="sensorEventView"
          label={uiStringsEt.sensorEventsLabel}
        />
        <ListField
          source="sensorReportView"
          label={uiStringsEt.sensorReportsLabel}
        />
        {canEdit && <EditButton label={uiStringsEt.editButtonLabel} />}
      </Datagrid>
    </List>
  );
};

export default UserGroupList;
