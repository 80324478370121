import React from 'react';
import {
  CreateProps,
  Create,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
  NumberInput,
  TitleProps,
  RadioButtonGroupInput,
  useGetList,
} from 'react-admin';

import { uiStringsEt } from '../../../../i18n/ui.strings';

const Title = ({ record }: TitleProps): React.ReactElement => (
  <>Lisa uus läbipääsusüsteem</>
);

const GatewayCreate = (props: CreateProps): React.ReactElement => {
  const { data } = useGetList('sensor-list/gateway/valid', {
    page: 1,
    perPage: 10000,
  });
  const sensors = Object.values(data).map((sensor) => ({
    ...sensor,
    name: sensor.deviceId,
  }));

  return (
    <Create {...props} title={<Title />}>
      <SimpleForm redirect="list">
        <TextInput
          label="Nimi/kirjeldus"
          source="name"
          validate={[required()]}
        />
        <TextInput label="Asukoht" source="location" validate={[required()]} />
        <TextInput label="Täpne aadress" source="address" />
        <ReferenceInput
          label="Läbipääsusüsteemi tüüp"
          reference="door-model"
          source="doorModelId"
          validate={[required()]}
          defaultValue="a3e088d1-d373-4616-a6b2-0cb0a0f4e5f0"
        >
          <SelectInput source="name" />
        </ReferenceInput>
        <RadioButtonGroupInput
          label="Intervalli valik"
          source="maintenanceInterval"
          choices={[
            {
              id: 6,
              name: '6 kuud',
            },
            {
              id: 12,
              name: '12 kuud',
            },
          ]}
        />
        <NumberInput
          label="Hoolduse intervalli tsüklid"
          source="maintenanceCycles"
          validate={[required()]}
        />
        <NumberInput
          label="Vedru eluiga"
          source="springReplacementCycles"
          validate={[required()]}
        />
        <SelectInput
          source="sensorId"
          label="Anduri valik (vali ID)"
          choices={sensors}
        />
        <TextInput label="Mooduli valik" disabled source="" />
        <TextInput label="Kontroller" source="controller" />
        <ReferenceInput
          label={uiStringsEt.clientNameLabel}
          reference="client"
          source="clientId"
          validate={[required()]}
        >
          <SelectInput source="name" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};

export default GatewayCreate;
