import React, { ReactElement } from 'react';
import { Admin, Resource, ResourceProps } from 'react-admin';
import { Route } from 'react-router-dom';
import {
  ShopTwo,
  PeopleAlt,
  MeetingRoom,
  Iso,
  AccountBox,
  Subject,
  Event,
} from '@material-ui/icons';

import { dataProvider } from 'providers/dataProvider';
import { AppProviders } from 'providers/AppProviders';

import { i18nProvider } from '../i18n';
import authProvider from '../service/authProvider.service';
import { canDo, resourceRoutes, resourceToPermissionMap } from '../utils';
import { Permissions } from '../utils/interfaces';
import AdminLayout from './components/AdminLayout';
import ClientCreate from './pages/Client/ClientCreate';
import ClientEdit from './pages/Client/ClientEdit';
import ClientList from './pages/Client/ClientList';
import DoorModelCreate from './pages/DoorModel/DoorModelCreate';
import DoorModelEdit from './pages/DoorModel/DoorModelEdit';
import DoorModeList from './pages/DoorModel/DoorModeList';
import LoginPage from './pages/Login';
import ProfileEdit from './pages/Profile/ProfileEdit';
import SensorCreate from './pages/Sensor/SensorCreate/SensorCreate';
import SensorEdit from './pages/Sensor/SensorEdit';
import SensorListView from './pages/Sensor/SensorListView';
import SensorEventListView from './pages/SensorEvent/SensorEventListView';
import SensorReportList from './pages/SensorReport/SensorReportList';
import ServerLogList from './pages/ServerLog/ServerLogList';
import GatewayCreate from './pages/Gateway/GatewayCreate';
import GatewayEdit from './pages/Gateway/GatewayEdit';
import UserCreate from './pages/User/UserCreate';
import UserList from './pages/User/UserList';
import UserGroupList from './pages/UserGroup/UserGroupList';
import UserEdit from './pages/User/UserEdit';
import UserGroupEdit from './pages/UserGroup/UserGroupEdit';
import UserGroupCreate from './pages/UserGroup/UserGroupCreate';
import SensorLogList from './pages/SensorLog/SensorLogList';
import ControllerListView from './pages/RemoteController/ControllerListView';
import DoorModelControllerCreate from './pages/DoorControllerModel/DoorControllerCreate/DoorControllerCreate';
import DoorModelControllerEdit from './pages/DoorControllerModel/DoorControllerEdit/DoorControllerEdit';
import DoorModelControllerList from './pages/DoorControllerModel/DoorControllerList/DoorControllerList';
import RemoteControllerEvent from './pages/RemoteControllerEvent';
import FileUpload from './pages/FileUpload/FileUpload';
import DeviceUpdates from './pages/DeviceUpdates';
import { uiStringsEt } from '../i18n/ui.strings';
import SensorManagementListView from './pages/SensorManagement/SensorManagementListView';
import SensorManagementCreate from './pages/SensorManagement/SensorManagementCreate';
import SensorManagementEdit from './pages/SensorManagement/SensorManagementEdit';
import ResellerList from './pages/Reseller/ResellerList';
import ResellerCreate from './pages/Reseller/ResellerCreate';
import ResellerEdit from './pages/Reseller/ResellerEdit';
import GatewayList from './pages/Gateway/GatewayList';

const ResourceWrapper = (
  props: ResourceProps & {
    name: typeof resourceRoutes[number];
    permissions?: Partial<Permissions>;
  },
) => {
  const {
    name,
    permissions: { permissions, clearance } = {},
    list: List,
    create: Create,
    edit: Edit,
  } = props;
  const canResource = canDo(
    name,
    clearance,
    permissions?.[resourceToPermissionMap[name]],
  );
  return (
    <Resource
      {...props}
      list={canResource.READ ? List : undefined}
      create={canResource.CREATE ? Create : undefined}
      edit={canResource.UPDATE ? Edit : undefined}
    />
  );
};
export const App = (): ReactElement => (
  <AppProviders>
    <Admin
      locale="et"
      dataProvider={dataProvider}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      loginPage={LoginPage}
      layout={AdminLayout}
      customRoutes={[
        <Route key="my-profile" path="/my-profile" component={ProfileEdit} />,
        <Route key="file-upload" path="/file-upload" component={FileUpload} />,
      ]}
    >
      {(permissions: Partial<Permissions>) => [
        <ResourceWrapper
          permissions={permissions}
          name="reseller"
          list={ResellerList}
          create={ResellerCreate}
          edit={ResellerEdit}
          options={{ label: 'Edasimüüjad' }}
          icon={() => <ShopTwo />}
        />,
        <ResourceWrapper
          permissions={permissions}
          name="client"
          list={ClientList}
          create={ClientCreate}
          edit={ClientEdit}
          options={{ label: 'Lõppkliendid' }}
          icon={() => <PeopleAlt />}
        />,
        <ResourceWrapper
          permissions={permissions}
          name="gateway"
          list={GatewayList}
          create={GatewayCreate}
          edit={GatewayEdit}
          options={{ label: uiStringsEt.gatewaysLabel }}
          icon={() => <MeetingRoom />}
        />,
        <ResourceWrapper
          name="sensor-list"
          permissions={permissions}
          list={SensorManagementListView}
          create={SensorManagementCreate}
          edit={SensorManagementEdit}
          options={{ label: uiStringsEt.remoteControllersLabel }}
          icon={() => <Iso />}
        />,
        <ResourceWrapper name="sensor-list/gateway/valid" />,
        <ResourceWrapper name="sensor-list/reseller/valid" />,
        <ResourceWrapper
          permissions={permissions}
          name="door-controller-model"
          list={DoorModelControllerList}
          create={DoorModelControllerCreate}
          edit={DoorModelControllerEdit}
          options={{ label: uiStringsEt.doorControllerModelsLabel }}
        />,
        <ResourceWrapper
          name="door-model"
          permissions={permissions}
          list={DoorModeList}
          create={DoorModelCreate}
          edit={DoorModelEdit}
          options={{ label: uiStringsEt.doorModelsLabel }}
        />,
        <ResourceWrapper
          permissions={permissions}
          name="user"
          list={UserList}
          create={UserCreate}
          edit={UserEdit}
          options={{ label: uiStringsEt.usersLabel }}
          icon={() => <AccountBox />}
        />,
        <ResourceWrapper
          permissions={permissions}
          name="user-group"
          list={UserGroupList}
          edit={UserGroupEdit}
          create={UserGroupCreate}
          options={{ label: uiStringsEt.userGroupsLabel }}
        />,
        <ResourceWrapper
          name="server-log"
          permissions={permissions}
          list={ServerLogList}
          options={{ label: uiStringsEt.serverLogsLabel }}
          icon={() => <Subject />}
        />,
        <ResourceWrapper
          permissions={permissions}
          name="sensor-event-list-view"
          list={SensorEventListView}
          options={{ label: uiStringsEt.sensorEventsLabel }}
          icon={() => <Event />}
        />,
        <ResourceWrapper
          name="sensor-list-view"
          permissions={permissions}
          list={SensorListView}
          create={SensorCreate}
          edit={SensorEdit}
          options={{ label: uiStringsEt.sensorsLabel }}
        />,
      ]}
    </Admin>
  </AppProviders>
);
