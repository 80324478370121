import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';
import { resolveBrowserLocale, TranslationMessages } from 'react-admin';
import estonianMessages from 'ra-language-estonian';

import english from './en-US.json';
import estonian from './et-ET.json';

const strings = {
  ...estonianMessages,
  ...estonian,
};
export const i18nProvider = polyglotI18nProvider(
  (locale) => <TranslationMessages>(<unknown>estonian),
);
