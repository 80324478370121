import React from 'react';
import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  ListProps,
  TextField,
} from 'react-admin';

import { uiStringsEt } from '../../../../i18n/ui.strings';

type ServerLog = { id: string; time?: Date; level?: number };

const ServerLogList = (props: ListProps): React.ReactElement => (
  <List {...props}>
    <Datagrid>
      <DateField
        source="time"
        showTime
        locales="et-ET"
        label={uiStringsEt.reportTimeLabel}
      />
      <FunctionField<ServerLog>
        label={uiStringsEt.levelLabel}
        render={({ level } = { level: 0, id: '' }) => {
          let color;
          let message;
          switch (level) {
            case 1:
              color = '#ffb52e';
              message = 'WARN';
              break;
            case 2:
              color = '#ff1919';
              message = 'ERROR';
              break;
            case 0:
            default:
              color = '#25a183';
              message = 'INFO';
              break;
          }
          return <span style={{ color }}>{message}</span>;
        }}
      />
      <TextField source="event" label={uiStringsEt.eventLabel} />
      <TextField source="meta" label={uiStringsEt.metaLabel} />
    </Datagrid>
  </List>
);

export default ServerLogList;
