import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { AxiosError } from 'axios';
import React, { useState } from 'react';
import { useRedirect, useTranslate } from 'react-admin';
import { useQuery } from 'react-query';
import styled from 'styled-components';

import BackendService from 'service/BackendService';

import { useResetDeviceId, useSelectedItemId } from './hooks';

const Layout = styled(Paper)`
  width: 20rem;

  padding: ${({ theme }) => theme.spacing(3, 3, 3)};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: grid;
  grid-auto-flow: row;
  justify-items: start;
  gap: 0.75rem;

  h5 {
    font-size: 1.5rem;
    margin: auto auto 1rem;
  }

  h6 {
    color: red;
  }

  div {
    min-width: 12.8rem;
  }

  div:last-child {
    margin: auto;

    display: grid;
    grid-auto-flow: column;
    gap: 1rem;
  }
`;

type Props = {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  basePath: string | undefined;
  editSensorId?: string;
  isCreate: boolean;
  sensorId?: string;
};

const ReplaceModal = ({
  setIsModalOpen,
  isModalOpen,
  basePath,
  editSensorId,
  isCreate,
  sensorId = '',
}: Props): React.ReactElement => {
  const t = useTranslate();
  const redirect = useRedirect();
  const { data } = useQuery('replace sensors', BackendService.getSensors);

  const [
    {
      value: selectedItemId,
      error: selectedItemIdError,
      touched: selectedItemIdTouched,
    },
    selectedItemIdDispatch,
  ] = useSelectedItemId();
  const [
    { value: deviceId, error: deviceIdError, touched: deviceIdTouched },
    deviceIdDispatch,
  ] = useResetDeviceId(sensorId);

  const [checked, setChecked] = useState(isCreate);
  const [errorMessage, setErrorMessage] = useState('');

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const isFormValid = () =>
    deviceIdTouched &&
    !deviceIdError &&
    selectedItemIdTouched &&
    !selectedItemIdError;

  return (
    <Modal open={isModalOpen} onClose={handleCloseModal}>
      <Layout>
        <Typography component="h5">Replace sensor</Typography>
        {errorMessage && (
          <Typography component="h6">
            {t(`app.sensors.replace.${errorMessage}`, {
              allowMissing: true,
              onMissingKey: () => 'Server failed to save',
            })}
          </Typography>
        )}
        <FormControl variant="outlined">
          <InputLabel id="selectedItemId">Selected sensor</InputLabel>
          <Select
            labelId="selectedItemId"
            variant="outlined"
            label="Selected sensor"
            value={selectedItemId}
            onChange={(e) => {
              setErrorMessage('');
              selectedItemIdDispatch(e.target.value as string);
            }}
            error={selectedItemIdError && selectedItemIdTouched}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {data?.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.shortDescription}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          variant="outlined"
          error={deviceIdError && deviceIdTouched}
          label="Sensor id"
          disabled={sensorId !== undefined}
          value={deviceId}
          onChange={(e) => {
            setErrorMessage('');
            deviceIdDispatch(e.target.value);
          }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              disabled={isCreate}
              onChange={(e) => setChecked(e.target.checked)}
              name="checkedB"
              color="primary"
            />
          }
          label="Apply selected sensor data"
        />
        <div>
          <Button
            variant="contained"
            color="primary"
            disabled={!isFormValid()}
            onClick={async () => {
              if (isFormValid()) {
                try {
                  const id = await BackendService.replaceSensor({
                    editSensorId,
                    selectedItemId,
                    checked,
                    deviceId,
                  });
                  redirect('edit', basePath, id);
                  handleCloseModal();
                } catch (e) {
                  const {
                    response: { data: { message } = { message: '' } } = {},
                  } = e as AxiosError<{ message: string }>;
                  setErrorMessage(message);
                }
              } else {
                selectedItemIdDispatch(undefined);
                deviceIdDispatch(undefined);
              }
            }}
          >
            Confirm
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleCloseModal}
          >
            Cancel
          </Button>
        </div>
      </Layout>
    </Modal>
  );
};

export default ReplaceModal;
