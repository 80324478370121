import React, { ReactElement } from 'react';
import {
  Datagrid,
  FilterProps,
  List,
  ListProps,
  NumberField,
  TextField,
  TextInput,
  Filter,
  EditButton,
} from 'react-admin';

import { Permissions } from 'utils/interfaces';
import { useCanEdit } from 'app/hooks';

import { uiStringsEt } from '../../../../i18n/ui.strings';

const DoorControllerModelFilter = (
  props: Partial<FilterProps>,
): ReactElement => (
  <Filter {...props}>
    <TextInput label={uiStringsEt.searchLabel} source="name" alwaysOn />
  </Filter>
);

const DoorControllerList = ({
  permissions,
  ...props
}: ListProps & { permissions?: Permissions }): React.ReactElement => {
  const canEdit = useCanEdit('doorControllerModel', permissions);
  return (
    <List {...props} filters={<DoorControllerModelFilter />}>
      <Datagrid>
        <TextField source="name" label={uiStringsEt.modelNameLabel} />
        <NumberField source="modelid" label={uiStringsEt.modelNoLabel} />
        {canEdit && <EditButton label={uiStringsEt.editButtonLabel} />}
      </Datagrid>
    </List>
  );
};

export default DoorControllerList;
