import React from 'react';
import { AppBar, AppBarProps } from 'react-admin';

import AdminUserMenu from '../AdminUserMenu';

const AdminAppBar = (props: AppBarProps): React.ReactElement => (
  <AppBar {...props} userMenu={<AdminUserMenu />} />
);

export default AdminAppBar;
