import type { Theme } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core';
import { defaultTheme } from 'react-admin';

const createBaseTheme = (isDarkMode: boolean): Theme =>
  createMuiTheme(
    {
      isDarkMode,
      palette: {
        type: isDarkMode ? 'dark' : 'light',
      },
    },
    defaultTheme,
  );

export default createBaseTheme;
