import { stringify } from 'querystring';

import {
  CondOperator,
  QueryFilter,
  QuerySort,
  RequestQueryBuilder,
} from '@nestjsx/crud-request';
import axios from 'axios';
import provider from 'ra-data-nestjsx-crud';
import { fetchUtils, DataProvider, Identifier } from 'react-admin';

import { USER_LS_DATA } from '../service/authProvider.service';
import { UpdateUserProfile } from './dataProviderInterface';

const apiUrl = '/api';

export const composeFilter = (paramsFilter: unknown): QueryFilter[] => {
  const flatFilter = fetchUtils.flattenObject(paramsFilter);
  return Object.keys(flatFilter).map((key) => {
    const splitKey = key.split('||');

    let [field, operator] = splitKey;
    const value = flatFilter[key];
    if (!operator) {
      if (typeof value === 'number' || value.match(/^\d+$/)) {
        operator = CondOperator.EQUALS;
      } else {
        operator = CondOperator.CONTAINS;
      }
    }

    if (field.startsWith('_') && field.includes('.')) {
      [, field] = field.split(/\.(.+)/);
    }
    return { field, operator, value } as QueryFilter;
  });
};

const composeQueryParams = (queryParams: unknown = {}): string =>
  stringify(fetchUtils.flattenObject(queryParams));

const mergeEncodedQueries = (...encodedQueries: unknown[]) =>
  encodedQueries.map((query) => query).join('&');

interface RAIdentifier {
  id: string | number;
  [key: string]: unknown;
}

const httpClient = (url: string, options = {} as fetchUtils.Options) =>
  fetchUtils.fetchJson(url, {
    ...options,
    credentials: 'include',
  });
const crudProvider = provider(apiUrl, httpClient);

export const dataProvider: DataProvider = {
  ...crudProvider,
  getList: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { q: queryParams, ...filter } = params.filter || {};

    const encodedQueryParams = composeQueryParams(queryParams);
    const encodedQueryFilter = RequestQueryBuilder.create({
      filter: composeFilter(filter),
    })
      .setLimit(perPage)
      .setPage(page)
      .sortBy(params.sort as QuerySort)
      .setOffset((page - 1) * perPage)
      .query();

    const query = mergeEncodedQueries(encodedQueryParams, encodedQueryFilter);

    const url = `${apiUrl}/${resource}?${query}`;
    const {
      json: { data, total },
    } = await httpClient(url);

    return { data, total };
  },
  getMany: async (resource, params) => {
    const filterParams = params.ids.flatMap((param) => {
      const p = param as Identifier | RAIdentifier;
      if (Array.isArray(p)) {
        return p;
      }
      if (typeof p !== 'string' && typeof p !== 'number') {
        return [p.id];
      }
      return [p];
    });
    const query = RequestQueryBuilder.create()
      .setFilter({
        field: 'id',
        operator: CondOperator.IN,
        value: `${filterParams}`,
      })
      .query();

    const url = `${apiUrl}/${resource}?${query}`;
    const { json: data } = await httpClient(url);

    return { data };
  },
  async updateUserProfile({
    data: { currentPassword, passwordRepeat, username, email, password },
  }: {
    data: UpdateUserProfile;
  }) {
    const { sub } = JSON.parse(localStorage.getItem(USER_LS_DATA) ?? '') ?? {};
    return axios.post(`${apiUrl}/user/update-profile`, {
      id: sub,
      username,
      email,
      currentPassword,
      password,
      passwordRepeat,
    });
  },
};
