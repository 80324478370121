import { AxiosError } from 'axios';
import { useCallback, useState } from 'react';
import { useDataProvider, useNotify } from 'react-admin';

import { useProfileDispatch } from '@store/profile/store';

export const useHandleSave = (): [unknown, boolean] => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [saving, setSaving] = useState(false);
  const dispatchProfile = useProfileDispatch();

  const handleSave = useCallback(
    (values) => {
      setSaving(true);
      dataProvider.updateUserProfile(
        { data: values },
        {
          onSuccess: () => {
            setSaving(false);
            notify('Your profile has been updated', 'info', {
              _: 'Your profile has been updated',
            });
            dispatchProfile();
          },
          onFailure: (message: AxiosError) => {
            setSaving(false);
            notify(message.response?.data.message, 'warning', {
              _: message.response?.data.message,
            });
          },
        },
      );
    },
    [dataProvider, notify, dispatchProfile],
  );
  return [handleSave, saving];
};
