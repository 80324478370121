import React from 'react';
import {
  Edit,
  EditProps,
  email,
  NumberInput,
  required,
  SimpleForm,
  TextInput,
  TitleProps,
  SelectArrayInput,
  useGetList,
  useGetOne,
  ReferenceArrayInput,
  Toolbar,
  SaveButton,
  DeleteButton,
} from 'react-admin';
import { makeStyles } from '@material-ui/core';

import GenerateTokenField from './GenerateTokenField';

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const Title = ({ record }: TitleProps): React.ReactElement => (
  <>Edasimüüja - {record?.name}</>
);

const ResellerToolbar: typeof Toolbar = (props) => {
  const { record } = props;
  return (
    <Toolbar {...props} classes={useStyles()}>
      <SaveButton />
      <DeleteButton mutationMode="pessimistic" />
    </Toolbar>
  );
};

const ResellerEdit = (props: EditProps): React.ReactElement => {
  // eslint-disable-next-line react/destructuring-assignment
  const editingResellerDataId = props.id ? props.id : '';
  const { data: editingReseller } = useGetOne(
    'reseller',
    editingResellerDataId,
  );

  return (
    <Edit {...props} title={<Title />}>
      <SimpleForm
        redirect="list"
        initialValues={{}}
        toolbar={<ResellerToolbar />}
      >
        <TextInput source="name" validate={[required()]} label="Nimi" />
        <TextInput
          label="Registrinumber"
          source="registerNo"
          validate={[required()]}
        />
        <TextInput label="Käibemaksukohuslase number" source="vatNo" />
        <TextInput
          label="Kontaktisiku nimi"
          source="contactName"
          validate={[required()]}
        />
        <TextInput
          label="Telefoninumber"
          source="phone"
          validate={[required()]}
        />
        <TextInput
          label="Kontaktisiku email"
          source="contactEmail"
          validate={[required(), email()]}
        />
        <TextInput
          label="Arve email"
          source="invoiceEmail"
          validate={[required(), email()]}
        />
        <TextInput label="Aadress" source="address" validate={[required()]} />
        <ReferenceArrayInput
          reference="sensor-list/reseller/valid"
          source="sensorsIds"
          label="Andurid"
        >
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
        <GenerateTokenField
          label="Edasimüüja API Token"
          source="resellerApiToken"
          resellerId={editingResellerDataId}
        />
      </SimpleForm>
    </Edit>
  );
};

export default ResellerEdit;
