import React, { ChangeEvent, useEffect, useState } from 'react';
import type { CreateProps, DataProvider } from 'react-admin';
import {
  BooleanInput,
  Create,
  email,
  minLength,
  RadioButtonGroupInput,
  ReferenceInput,
  regex,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useDataProvider,
  useGetIdentity,
  useGetList,
  useGetOne,
  useRecordContext,
} from 'react-admin';

import { Clearance, Permissions } from 'utils/interfaces';

import { passesClearance } from '../../../../utils';
import { uiStringsEt } from '../../../../i18n/ui.strings';

type FieldProps = {
  username: string;
  email: string;
  password: string;
  passwordRepeat: string;
  userGroupId: string;
  enabled: boolean;
};

// to fake IDE locale plugin
const t = (s: string) => s;

const validate = (props: FieldProps) => {
  const errors: Partial<FieldProps> = {};
  if (props.passwordRepeat !== props.password) {
    errors.passwordRepeat = t(
      'app.user.create.fields.passwordRepeat.error.match',
    );
  }
  return errors;
};

const getUserTypeChoicesBasedOnLoggedInClearance = (
  clearance: string | null,
) => {
  if (clearance) {
    switch (clearance) {
      case Clearance.ROOT:
        return [
          {
            id: '6e077624-29f4-44e2-a109-bc759808267f',
            name: 'Admin',
          },
          {
            id: '4d3fc63f-66fe-45c9-8bb0-55f32f2b72b4',
            name: 'Edasimüüja',
          },
          {
            // eslint-disable-next-line sonarjs/no-duplicate-string
            id: '9b367f02-77e0-43b8-85bb-3f8fca6ca35d',
            // eslint-disable-next-line sonarjs/no-duplicate-string
            name: 'Lõppklient',
          },
        ];
      case Clearance.RESELLER:
        return [
          {
            id: '4d3fc63f-66fe-45c9-8bb0-55f32f2b72b4',
            name: 'Edasimüüja',
          },
          {
            id: '9b367f02-77e0-43b8-85bb-3f8fca6ca35d',
            name: 'Lõppklient',
          },
        ];
      case Clearance.CLIENT:
        return [
          {
            id: '9b367f02-77e0-43b8-85bb-3f8fca6ca35d',
            name: 'Lõppklient',
          },
        ];
      default:
        return [];
    }
  }
  return [];
};

const UserCreate = ({
  permissions,
  ...props
}: CreateProps & { permissions?: Permissions }): React.ReactElement => {
  const clearanceOptions: { id: keyof typeof Clearance; name: string }[] = [
    {
      id: Clearance.ROOT,
      name: t('app.user.create.fields.clearance.option.root'),
    },
    {
      id: Clearance.CLIENT,
      name: t('app.user.create.fields.clearance.option.client'),
    },
    {
      id: Clearance.RESELLER,
      name: t('app.user.create.fields.clearance.option.reseller'),
    },
    {
      id: Clearance.SITE,
      name: t('app.user.create.fields.clearance.option.site'),
    },
  ].filter((clearance) =>
    passesClearance(permissions?.clearance, clearance.id),
  );

  const { loaded, identity } = useGetIdentity();
  const userDataId = identity ? identity.id : '';
  const { data: user, loading, error } = useGetOne('user', userDataId);
  const userClearance = user ? user.clearance : null;

  const [selectedUserType, setSelectedUserType] = useState<any>(null);

  return (
    <Create {...props} title={<>Lisa uus kasutaja</>}>
      <SimpleForm validate={validate} redirect="list">
        <RadioButtonGroupInput
          label={false}
          source="userGroupId"
          choices={getUserTypeChoicesBasedOnLoggedInClearance(userClearance)}
          onChange={(e) => {
            setSelectedUserType(e);
          }}
        />
        <TextInput
          label={uiStringsEt.usernameLabel}
          source="username"
          validate={[required()]}
        />
        <TextInput
          source="email"
          type="email"
          validate={[required(), email()]}
        />
        <TextInput
          label={uiStringsEt.passwordLabel}
          source="password"
          type="password"
          validate={[
            required(),
            regex(
              /(?=.*[a-z])/,
              t('app.user.create.fields.password.error.lowerCase'),
            ),
            regex(
              /(?=.*[A-Z])/,
              t('app.user.create.fields.password.error.capitalLetter'),
            ),
            regex(
              /(?=.*[0-9])/,
              t('app.user.create.fields.password.error.number'),
            ),
            minLength(8),
          ]}
        />
        <TextInput
          label={uiStringsEt.passwordRepeatLabel}
          source="passwordRepeat"
          type="password"
          validate={[required()]}
        />
        {/* eslint-disable-next-line no-nested-ternary */}
        {selectedUserType === '4d3fc63f-66fe-45c9-8bb0-55f32f2b72b4' &&
        user &&
        user.reseller ? (
          <ReferenceInput
            label="Edasimüüja"
            reference="reseller"
            source="resellerId"
            validate={[required()]}
          >
            <SelectInput
              source="name"
              defaultValue={user.reseller.id}
              disabled
            />
          </ReferenceInput>
        ) : selectedUserType === '4d3fc63f-66fe-45c9-8bb0-55f32f2b72b4' ? (
          <ReferenceInput
            label="Edasimüüja"
            reference="reseller"
            source="resellerId"
            validate={[required()]}
          >
            <SelectInput source="name" />
          </ReferenceInput>
        ) : null}

        {/* eslint-disable-next-line no-nested-ternary */}
        {selectedUserType === '9b367f02-77e0-43b8-85bb-3f8fca6ca35d' &&
        user &&
        user.client ? (
          <ReferenceInput
            label="Lõpp-klient"
            reference="client"
            source="clientId"
            validate={[required()]}
          >
            <SelectInput source="name" defaultValue={user.client.id} disabled />
          </ReferenceInput>
        ) : selectedUserType === '9b367f02-77e0-43b8-85bb-3f8fca6ca35d' ? (
          <ReferenceInput
            label="Lõpp-klient"
            reference="client"
            source="clientId"
            validate={[required()]}
          >
            <SelectInput source="name" />
          </ReferenceInput>
        ) : null}
      </SimpleForm>
    </Create>
  );
};

export default UserCreate;
