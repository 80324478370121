import React, { useEffect, useState } from 'react';
import {
  Edit,
  EditProps,
  ReferenceInput,
  SimpleForm,
  TextInput,
  SelectInput,
  TitleProps,
  required,
  NumberInput,
  RadioButtonGroupInput,
  FieldProps,
  useRecordContext,
  useListContext,
  useGetList,
  useGetOne,
  useGetIdentity,
  Toolbar,
  SaveButton,
  DeleteButton,
} from 'react-admin';
import styled from 'styled-components';
import {
  Button,
  makeStyles,
  Modal,
  Paper,
  Typography,
} from '@material-ui/core';

import { uiStringsEt } from '../../../../i18n/ui.strings';
import BackendService from '../../../../service/BackendService';
import { Clearance } from '../../../../utils/interfaces';

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const ConfirmModalPaper = styled(Paper)`
  position: absolute;
  width: 30rem;
  padding: 2rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: auto auto;
  justify-items: center;
  row-gap: 3rem;

  span {
    grid-column: 1 / span 2;
    text-align: center;
  }

  button {
    grid-area: 2;
    min-width: 8rem;
  }
`;

type RemoteControllerCommand = {
  id: string;
  command: number;
  deviceId: string;
  rssi: number;
  lastCommand: string;
  walkDoorStatus: boolean;
  sensor1Status: boolean;
  sensor2Status: boolean;
  controllerId: string;
  doorcontrollermodelid: string;
};

type SensorModelReportClientSiteProps = {
  id: number;
  cycles: number;
  maintenanceCycle: number;
  batteryLife: number;
};

const StopButton = styled(Button)`
  background-color: #f44336;
`;

// const CycleResetButton: React.FC<FieldProps<SensorModelReportClientSiteProps>> =
//   (props) => {
//     const t = useTranslate();
//     const { id, cycles } = useRecordContext(props);
//     const { refetch } = useListContext();
//     const [modalOpen, setModalOpen] = useState(false);

//     const handleReset = async () => {
//       await BackendService.resetController(id, cycles);
//       refetch();
//       setModalOpen(false);
//     };
//     return (
//       <>
//         <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
//           <ConfirmModalPaper>
//             <Typography component="span" variant="subtitle1">
//               {t('app.sensors.list.reset.confirm.label')}
//             </Typography>
//             <Button variant="contained" color="primary" onClick={handleReset}>
//               {t('app.labels.yes')}
//             </Button>
//             <Button
//               variant="contained"
//               color="secondary"
//               onClick={() => setModalOpen(false)}
//             >
//               {t('app.labels.no')}
//             </Button>
//           </ConfirmModalPaper>
//         </Modal>
//         <Button variant='outlined' color='primary' onClick={() => setModalOpen(true)}>
//           {t('app.sensors.list.reset.label')}
//         </Button>
//       </>
//     );
//   };

const ControllerCommandsButton: React.FC<FieldProps<RemoteControllerCommand>> =
  (props) => {
    const {
      deviceId,
      rssi,
      walkDoorStatus,
      sensor1Status,
      sensor2Status,
      id,
      doorcontrollermodelid,
    } = useRecordContext(props);
    const { refetch } = useListContext();
    const [modalOpen, setModalOpen] = useState(false);

    const openCommand = async () => {
      try {
        await BackendService.controllerCommands({
          deviceId,
          command: 2,
          rssi,
          lastCommand: 'Open',
          walkDoorStatus,
          sensor1Status,
          sensor2Status,
          controllerId: id,
        });
      } catch (e) {
        alert('Something went wrong. Please try again!');
      }
      refetch();
      setModalOpen(false);
    };
    const closeCommand = async () => {
      try {
        await BackendService.controllerCommands({
          deviceId,
          command: 1,
          rssi,
          lastCommand: 'Close',
          walkDoorStatus,
          sensor1Status,
          sensor2Status,
          controllerId: id,
        });
      } catch (e) {
        alert('Something went wrong. Please try again!');
      }
      refetch();
      setModalOpen(false);
    };
    const stopCommand = async () => {
      try {
        await BackendService.controllerCommands({
          deviceId,
          command: 0,
          rssi,
          lastCommand: 'Stop',
          walkDoorStatus,
          sensor1Status,
          sensor2Status,
          controllerId: id,
        });
      } catch (e) {
        alert('Something went wrong. Please try again!');
      }
      refetch();
      setModalOpen(false);
    };

    // If door model = ARGO, D600 or D1000, return different view.
    // doorcontrollermodelid === "a1e7d7b3-5712-4378-83e3-a1b237f47da4" ||   # ARGO model has three buttons
    if (
      doorcontrollermodelid === 'a17da6fa-07a3-40e2-a2fc-64a6b25ad051' ||
      doorcontrollermodelid === 'a337c7fb-640f-4099-b5bb-bc8ff1486f77'
    ) {
      return (
        <>
          <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
            <ConfirmModalPaper>
              <Button variant="contained" color="primary" onClick={openCommand}>
                AVA/SULGE/PEATA
              </Button>
              <StopButton variant="contained" onClick={stopCommand}>
                PEATA
              </StopButton>
            </ConfirmModalPaper>
          </Modal>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setModalOpen(true)}
          >
            Kontrolli
          </Button>
        </>
      );
    }
    return (
      <>
        <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
          <ConfirmModalPaper>
            <Button variant="contained" color="primary" onClick={openCommand}>
              AVA
            </Button>
            <Button variant="contained" color="primary" onClick={closeCommand}>
              Sulge
            </Button>
            <StopButton variant="contained" onClick={stopCommand}>
              PEATA
            </StopButton>
          </ConfirmModalPaper>
        </Modal>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setModalOpen(true)}
        >
          Kontrolli
        </Button>
      </>
    );
  };

const Title = ({ record }: TitleProps): React.ReactElement => (
  <>Muuda läbipääsusüsteemi - {record?.name}</>
);

// eslint-disable-next-line sonarjs/cognitive-complexity
const Commands = (props: any): React.ReactElement => {
  const record = useRecordContext();
  console.log('showing commands');
  if (record.sensor) {
    const { deviceId } = record.sensor;
    const openCommand = async () => {
      try {
        await BackendService.controllerCommands({
          deviceId,
          command: 2,
          lastCommand: 'Open',
        });
      } catch (e) {
        alert('Something went wrong. Please try again!');
      }
    };
    const closeCommand = async () => {
      try {
        await BackendService.controllerCommands({
          deviceId,
          command: 1,
          lastCommand: 'Close',
        });
      } catch (e) {
        alert('Something went wrong. Please try again!');
      }
    };
    const stopCommand = async () => {
      try {
        await BackendService.controllerCommands({
          deviceId,
          command: 0,
          lastCommand: 'Stop',
        });
      } catch (e) {
        alert('Something went wrong. Please try again!');
      }
    };
    const resetMaintenanceCommand = async () => {
      try {
        await BackendService.resetMaintenance({
          deviceId,
          gatewayId: record.id,
        });
        alert(`${record.name} hooldus lähtestatud.`);
      } catch (e) {
        alert('Something went wrong. Please try again!');
      }
    };

    const resetSpringCommand = async () => {
      try {
        await BackendService.resetSpring({
          deviceId,
          gatewayId: record.id,
        });
        alert(`${record.name} vedru lähtestatud.`);
      } catch (e) {
        alert('Something went wrong. Please try again!');
      }
    };

    if (record.sensor.moduleType === '24V mootor') {
      return (
        <span
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          {
            // props.clearance === Clearance.ROOT ||
            props.clearance === Clearance.CLIENT ? (
              <span>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={openCommand}
                  style={{ marginRight: '12px' }}
                >
                  AVA/SULGE/PEATA
                </Button>
                <StopButton
                  variant="contained"
                  onClick={stopCommand}
                  style={{ marginRight: '36px' }}
                >
                  PEATA
                </StopButton>
              </span>
            ) : null
          }
          {props.clearance === Clearance.ROOT ||
          props.clearance === Clearance.RESELLER ? (
            <span>
              <Button
                variant="contained"
                color="primary"
                onClick={resetMaintenanceCommand}
                style={{ marginRight: '12px' }}
              >
                Lähtesta Hooldus
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={resetSpringCommand}
                style={{ marginRight: '12px' }}
              >
                Lähtesta Vedru
              </Button>
            </span>
          ) : null}
        </span>
      );
    }
    return (
      <span
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        {
          // props.clearance === Clearance.ROOT ||
          props.clearance === Clearance.CLIENT ? (
            <span>
              <Button
                variant="contained"
                color="primary"
                onClick={openCommand}
                style={{ marginRight: '12px' }}
              >
                AVA
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={closeCommand}
                style={{ marginRight: '12px' }}
              >
                Sulge
              </Button>
              <StopButton
                variant="contained"
                onClick={stopCommand}
                style={{ marginRight: '36px' }}
              >
                PEATA
              </StopButton>
            </span>
          ) : null
        }
        {props.clearance === Clearance.ROOT ||
        props.clearance === Clearance.RESELLER ? (
          <span>
            <Button
              variant="contained"
              color="primary"
              onClick={resetMaintenanceCommand}
              style={{ marginRight: '12px' }}
            >
              Lähtesta Hooldus
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={resetSpringCommand}
              style={{ marginRight: '12px' }}
            >
              Lähtesta Vedru
            </Button>
          </span>
        ) : null}
      </span>
    );
  }
  return <span />;
};

const GatewayToolbar: typeof Toolbar = (props) => {
  const { record } = props;
  return (
    <Toolbar {...props} classes={useStyles()}>
      <SaveButton />
      <DeleteButton mutationMode="pessimistic" />
    </Toolbar>
  );
};

const GatewayEdit = (props: EditProps): React.ReactElement => {
  const { loaded, identity } = useGetIdentity();
  const userDataId = identity ? identity.id : '';
  const { data: user, loading, error } = useGetOne('user', userDataId);
  const userClearance = user ? user.clearance : null;

  // eslint-disable-next-line react/destructuring-assignment
  const editingGatewayDataId = props.id ? props.id : '';
  const { data: editingGateway } = useGetOne('gateway', editingGatewayDataId);

  const { data } = useGetList('sensor-list/gateway/valid', {
    page: 1,
    perPage: 10000,
  });
  const sensors = Object.values(data).map((sensor) => ({
    ...sensor,
    name: sensor.deviceId,
  }));
  if (editingGateway && editingGateway.sensor) {
    sensors.push({
      ...editingGateway.sensor,
      name: editingGateway.sensor.deviceId,
    });
  }

  return (
    <Edit {...props} title={<Title />}>
      <SimpleForm redirect="list" toolbar={<GatewayToolbar />}>
        <Commands clearance={userClearance} />
        <div style={{ display: 'flex', flexDirection: 'row' }} />
        <TextInput
          label="Nimi/kirjeldus"
          source="name"
          validate={[required()]}
        />
        <TextInput label="Asukoht" source="location" validate={[required()]} />
        <TextInput label="Täpne aadress" source="address" />
        <ReferenceInput
          label="Läbipääsusüsteemi tüüp"
          reference="door-model"
          source="doorModelId"
          validate={[required()]}
        >
          <SelectInput source="name" />
        </ReferenceInput>
        <RadioButtonGroupInput
          label="Intervalli valik"
          source="maintenanceInterval"
          choices={[
            {
              id: 6,
              name: '6 kuud',
            },
            {
              id: 12,
              name: '12 kuud',
            },
          ]}
        />
        <NumberInput
          label="Hoolduse intervalli tsüklid"
          source="maintenanceCycles"
          validate={[required()]}
        />
        <NumberInput
          label="Vedru eluiga"
          source="springReplacementCycles"
          validate={[required()]}
        />
        <ReferenceInput
          label="Anduri valik (vali ID)"
          reference="sensor-list/gateway/valid"
          source="sensorId"
        >
          <SelectInput source="name" />
        </ReferenceInput>
        <TextInput label="Mooduli valik" disabled source="sensor.moduleType" />
        <TextInput label="Kontroller" source="controller" />
        <ReferenceInput
          label={uiStringsEt.clientNameLabel}
          reference="client"
          source="clientId"
          validate={[required()]}
        >
          <SelectInput source="name" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};

export default GatewayEdit;
