/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import './index.css';
import axios from 'axios';
import { FileField, FileInput, SaveButton, SelectInput, SimpleForm, TextInput, Toolbar } from 'react-admin';

import { uiStringsEt } from "../../../i18n/ui.strings";

const hardwareFolders:any[] = [];
const softwareFolders:any[] = [];
const firmwareFiles:any[] = [];

async function findHardwareFolder(){
  axios
  .get('api/file-upload/findhardwarefolder')
  .then((response) => {
    if (response.status === 200) {
      for(let i = 0; i < response.data.response.length; i++){
        hardwareFolders.push({id: response.data.response[i], name: response.data.response[i]});
      }
    } else {
      alert("Something went wrong with finding hardware folders. Please try again!");
    }
  });
};

async function findSoftwareFolder(deleteHardware:string){
  softwareFolders.length = 0;
  firmwareFiles.length = 0;
  axios
  .post('api/file-upload/findsoftwarefolder',{deleteHardware})
  .then((response) => {
    if (response.status === 201) {
      for(let i = 0; i < response.data.response.length; i++){
        softwareFolders.push({id: response.data.response[i], name: response.data.response[i]});
      }
    } else {
      alert("Something went wrong with finding software folders. Please try again!");
    }
  });
};

async function findFiles(deleteHardware:string, deleteSoftware:string){
  firmwareFiles.length = 0;
  axios
  .post('http://localhost:5000/api/file-upload/findfiles', {deleteHardware, deleteSoftware})
  .then((response) => {
    if (response.status === 201) {
      for(let i = 0; i < response.data.response.length; i++){
        firmwareFiles.push({id: response.data.response[i], name: response.data.response[i]});
      }
    } else {
      alert("Something went wrong with finding firmware files. Please try again!");
    }
  });
};

findHardwareFolder();

function FileUpload(): React.ReactElement {

  const [hardware, setHardware] = useState('');
  const [software, setSoftware] = useState('');
  // const [files, setBinFile] = useState(Object);
  // const [binFileName, setBinFileName] = useState('');

  const [deleteHardware, setdeleteHardware] = useState('');
  const [deleteSoftware, setdeleteSoftware] = useState('');
  const [deleteFilename, setdeleteFilename] = useState('');

  async function blobToBase64(blob:Blob):Promise<string> {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.readAsDataURL(blob);
    });
  }

  async function uploadFirmware(e:any) {
    const endFileName = e.File.title;
    const blob = e.File.rawFile as Blob;
    const base64blob = await blobToBase64(blob);
    const slicedBlob = base64blob.slice('data:application/octet-stream;base64,'.length)

    axios
    .post('api/file-upload/new', {
      hardware,
      software,
      slicedBlob,
      endFileName,
    })
    .then((response) => {
      if (response.statusText === 'Created') {
        alert("New firmware uploaded!");
        // window.location.reload();
      } else {
        alert("Something went while uploading new firmware. Please try again!");
      }
    });
  }

  async function deleteFirmware(e:any) {
    axios
      .post('api/file-delete/delete', {
        deleteHardware,
        deleteSoftware,
        deleteFilename,
      })
      .then((response) => {
        if (response.statusText === 'Created') {
          alert("Firmware deleted!");
          window.location.reload();
        } else {
          alert("Something went wrong. Please try again!");
        }
      });
  }

  const DeleteToolbar = (props:any) => {
    console.log();
    return (
      <Toolbar {...props}>
        <SaveButton
          label={uiStringsEt.deleteButton}
          redirect={false}
          submitOnEnter={false}
          variant="contained"
          color="secondary"
          onSave={deleteFirmware}
        />
      </Toolbar>
    );
  };

  const UploadToolbar = (props:any) => {
    console.log();
    return (
      <Toolbar {...props}>
        <SaveButton
          label={uiStringsEt.uploadButton}
          redirect={false}
          submitOnEnter={false}
          variant="contained"
          color="primary"
          onSave={uploadFirmware}
        />
      </Toolbar>
    );
  };

  return (
    <div className="main">
      <table>
        <tbody>
          <tr>
            <td>
              <div className='table-data'>
                <h2>{uiStringsEt.uploadNewFirmWare}</h2>
                <SimpleForm toolbar={<UploadToolbar />}>
                  <TextInput
                    source={uiStringsEt.hardwareLabel}
                    value={hardware}
                    onChange={(e) => setHardware(e.target.value)}
                  />
                  <TextInput
                    source={uiStringsEt.softwareLabel}
                    value={software}
                    onChange={(e) => setSoftware(e.target.value)}
                  />
                  <FileInput source="File" label={uiStringsEt.relatedFiles} placeholder={<p>{uiStringsEt.dropYourBinFileHere}</p>} accept='.bin'>
                    <FileField source="src" title="title" />
                  </FileInput>
                </SimpleForm>
              </div>
            </td>

            <td>
              <div className='table-data'>
                <h2>{uiStringsEt.deleteFirmWare}</h2>
                <SimpleForm toolbar={<DeleteToolbar />}>
                  <SelectInput
                    source={uiStringsEt.hardwareLabel}
                    choices={hardwareFolders}
                    value={deleteHardware}
                    onChange={(e) => {setdeleteHardware(e.target.value); findSoftwareFolder(e.target.value)}}
                  />
                  <SelectInput
                    source={uiStringsEt.softwareLabel}
                    choices={softwareFolders}
                    value={deleteSoftware}
                    onChange={(e) => {setdeleteSoftware(e.target.value); findFiles(deleteHardware, e.target.value)}}
                  />
                  <SelectInput
                    source={uiStringsEt.fileLabel}
                    choices={firmwareFiles}
                    value={deleteFilename}
                    onChange={(e) => setdeleteFilename(e.target.value)}
                  />
                  <br />
                  <br />
                </SimpleForm>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default FileUpload;
