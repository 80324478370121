import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { downloadCSV, MenuItemLink, useGetList } from 'react-admin';
import { CloudDownload } from '@material-ui/icons';
import jsonExport from 'jsonexport';

import BackendService from '../../../service/BackendService';

const buttonStyle = {
  color: 'rgba(0, 0, 0, 0.54)',
  textDecoration: 'underline',
};
const AdminDownloadLogs: () => JSX.Element = () => {
  const location = useLocation();
  const [serverLogs, setServerLogs] = useState<any>([]);
  const [csvSource, setCsvSource] = useState<string>();
  const [key, setKey] = useState(0);
  const handleSensorEventClick = () => {
    setCsvSource(
      BackendService.getResourceCSV({
        resource: 'sensor-event-list-view',
        sort: {
          field: 'time',
          order: 'DESC',
        },
        filter: {},
      }),
    );
    setKey((prev) => prev + 1);
  };

  const { data: serverLogData } = useGetList('server-log', {
    page: 1,
    perPage: 100000,
  });

  // eslint-disable-next-line @kyleshevlin/prefer-custom-hooks
  useEffect(() => {
    const values = Object.values(serverLogData);
    if (values.length > 0) {
      setServerLogs(values);
    }
  }, [serverLogData]);

  const handleServerLogClick = () => {
    if (serverLogs.length > 0) {
      const parsedLogs = serverLogs.map((log: any) => ({
        ...log,
        meta: JSON.stringify(log.meta),
      }));
      jsonExport(
        parsedLogs,
        {
          headers: ['event', 'id', 'level', 'meta', 'time'],
        },
        (err, csv) => {
          downloadCSV(csv, 'server-log');
        },
      );
    }
  };
  return (
    <div>
      <MenuItemLink
        key="ServerLogs"
        to={location.pathname}
        primaryText="Serveri logid"
        leftIcon={<CloudDownload />}
        style={buttonStyle}
        onClick={handleServerLogClick}
      />
      <MenuItemLink
        key="SensorLogs"
        to={location.pathname}
        primaryText="Andurite sündmused"
        leftIcon={<CloudDownload />}
        style={buttonStyle}
        onClick={handleSensorEventClick}
      />
      <div style={{ display: 'none' }}>
        <iframe key={key} title="download" src={csvSource} />
      </div>
    </div>
  );
};

export default AdminDownloadLogs;
