import React from 'react';
import {
  Create,
  CreateProps,
  email,
  NumberInput,
  required, SelectArrayInput,
  SimpleForm,
  TextInput, useGetList
} from "react-admin";

import { uiStringsEt } from '../../../../i18n/ui.strings';

type ActionSensorButtonProps = {
  title: string;
};

export const ActionSensorButton = (
  props: ActionSensorButtonProps,
): React.ReactElement => {
  const { title } = props;
  return (
    <button
      type="button"
      style={{
        background: 'none',
        border: 'none',
        padding: 0,
        font: 'inherit',
        cursor: 'pointer',
        outline: 'inherit',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '15px',
        lineHeight: '150%',
        textAlign: 'right',
        color: '#0000FF',
        minWidth: '82px',
      }}
    >
      {title}
    </button>
  );
};

const ResellerCreate = (props: CreateProps): React.ReactElement => {
  const { data: sensorsData } = useGetList('sensor-list/reseller/valid', {
    page: 1,
    perPage: 10000,
  });
  const sensors = Object.values(sensorsData).map((sensor) => ({
    ...sensor,
    name: sensor.deviceId,
  }));

  return (
    <Create {...props} title={<>Lisa uus edasimüüja</>}>
      <SimpleForm redirect="list">
        <TextInput source="name" validate={[required()]} label="Nimi" />
        <TextInput
          label="Registrinumber"
          source="registerNo"
          validate={[required()]}
        />
        <TextInput label="Käibemaksukohuslase number" source="vatNo" />
        <TextInput
          label="Kontaktisiku nimi"
          source="contactName"
          validate={[required()]}
        />
        <TextInput
          label="Telefoninumber"
          source="phone"
          validate={[required()]}
        />
        <TextInput
          label="Kontaktisiku email"
          source="contactEmail"
          validate={[required(), email()]}
        />
        <TextInput
          label="Arve email"
          source="invoiceEmail"
          validate={[required(), email()]}
        />
        <TextInput label="Aadress" source="address" validate={[required()]} />
        <SelectArrayInput
          label="Andurid"
          source="sensors"
          allowEmpty
          choices={sensors}
        />
      </SimpleForm>
    </Create>
  );
};

export default ResellerCreate;
