import React from 'react';
import {
  DeleteButton,
  Edit,
  EditProps,
  email,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  TitleProps,
  Toolbar,
  useGetIdentity,
  useGetOne,
} from 'react-admin';
import { makeStyles } from '@material-ui/core';

import { uiStringsEt } from '../../../../i18n/ui.strings';
import { Clearance } from '../../../../utils/interfaces';

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const Title = ({ record }: TitleProps): React.ReactElement => (
  <>Lõppklient - {record?.name}</>
);

const ClientToolbar: typeof Toolbar = (props) => {
  const { record } = props;
  return (
    <Toolbar {...props} classes={useStyles()}>
      <SaveButton />
      <DeleteButton mutationMode="pessimistic" />
    </Toolbar>
  );
};

const ClientEdit = (props: EditProps): React.ReactElement => {
  const { loaded, identity } = useGetIdentity();
  const userDataId = identity ? identity.id : '';
  const { data: user, loading, error } = useGetOne('user', userDataId);
  const userClearance = user ? user.clearance : null;

  return (
    <Edit {...props} title={<Title />}>
      <SimpleForm redirect="list" toolbar={<ClientToolbar />}>
        <TextInput label="Nimi" source="name" validate={[required()]} />
        <TextInput label="Registrinumber" source="registerNo" />
        <TextInput label="Aadress" source="address" validate={[required()]} />
        <TextInput
          label="Käibemaksukohuslase number"
          source="vatNo"
          validate={[required()]}
        />
        <TextInput
          label="Kontaktisik"
          source="contactName"
          validate={[required()]}
        />
        <TextInput
          label="Kontakt e-mail"
          source="contactEmail"
          validate={[required(), email()]}
        />
        {userClearance === Clearance.RESELLER ? null : (
          <ReferenceInput
            label="Edasimüüja valik"
            reference="reseller"
            source="resellerId"
            validate={[required()]}
          >
            <SelectInput source="name" />
          </ReferenceInput>
        )}
      </SimpleForm>
    </Edit>
  );
};

export default ClientEdit;
