import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { DarkModeProvider } from '@store/darkMode/store';
import { AuthenticationProvider } from '@store/authentication/store';
import { ProfileProvider } from '@store/profile/store';

import ErrorBoundary from './ErrorBoundary';
import ThemeProvider from './Theme';

const queryClient = new QueryClient();

export const AppProviders: React.FC = ({ children }) => (
  <DarkModeProvider>
    <ThemeProvider>
      <ErrorBoundary>
        <AuthenticationProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <QueryClientProvider client={queryClient}>
              <ProfileProvider>
                <Router>{children}</Router>
              </ProfileProvider>
            </QueryClientProvider>
          </MuiPickersUtilsProvider>
        </AuthenticationProvider>
      </ErrorBoundary>
    </ThemeProvider>
  </DarkModeProvider>
);
