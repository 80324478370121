/* eslint-disable prefer-promise-reject-errors */
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { AuthProvider } from 'react-admin';

import { ApiJwtPayload, Permissions } from '../utils/interfaces';

export const USER_LS_DATA = 'user';

const authProvider: AuthProvider = {
  async login({
    password,
    username,
  }: {
    username: string;
    password: string;
  }): Promise<void> {
    const token = await axios.post<string>(
      '/api/auth/login',
      {
        username,
        password,
      },
      { withCredentials: true },
    );
    const user: ApiJwtPayload = jwtDecode(token.data);
    localStorage.setItem(USER_LS_DATA, JSON.stringify(user));
    return Promise.resolve();
  },
  async checkError(error): Promise<void> {
    const { status } = error;
    if (status === 401) {
      localStorage.removeItem(USER_LS_DATA);
      return Promise.reject();
    }
    if (status === 403) {
      return Promise.reject({ redirectTo: '/' });
    }
    return Promise.resolve();
  },
  async checkAuth(): Promise<void> {
    return localStorage.getItem(USER_LS_DATA)
      ? Promise.resolve()
      : Promise.reject();
  },
  async logout(): Promise<void | false | string> {
    localStorage.removeItem(USER_LS_DATA);
    await axios.post('/api/auth/logout', '', { withCredentials: true });
    return Promise.resolve();
  },
  async getIdentity() {
    const { sub, username, email } =
      JSON.parse(localStorage.getItem(USER_LS_DATA) ?? '{}') ?? {};
    if (sub && username) {
      return { id: sub, username, email };
    }
    return Promise.reject();
  },
  async getPermissions(): Promise<Permissions> {
    const { permissions, clearance } = (JSON.parse(
      localStorage.getItem(USER_LS_DATA) ?? '{}',
    ) ?? {}) as ApiJwtPayload;
    if (permissions !== undefined && clearance !== undefined) {
      return Promise.resolve({ permissions, clearance });
    }
    return Promise.reject();
  },
};

export default authProvider;
