import React from 'react';
import {
  Create,
  CreateProps,
  SelectArrayInput,
  SimpleForm,
  TextInput,
} from 'react-admin';

import { limitedRights, rights } from '../UserGroupEdit/UserGroupEdit';
import { uiStringsEt } from '../../../../i18n/ui.strings';

const UserGroupCreate = (props: CreateProps): React.ReactElement => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" label={uiStringsEt.userGroupLabel} />
      <SelectArrayInput
        source="sensor"
        choices={rights}
        label={uiStringsEt.sensorNameLabel}
      />
      <SelectArrayInput
        source="doorControllerModel"
        choices={rights}
        label={uiStringsEt.doorControllerModelLabel}
      />
      <SelectArrayInput
        source="doorModel"
        choices={rights}
        label={uiStringsEt.doorModelLabel}
      />
      <SelectArrayInput
        source="client"
        choices={rights}
        label={uiStringsEt.clientNameLabel}
      />
      <SelectArrayInput
        source="reseller"
        choices={rights}
        label={uiStringsEt.resellerNameLabel}
      />
      <SelectArrayInput
        source="site"
        choices={rights}
        label={uiStringsEt.gatewayNameLabel}
      />
      <SelectArrayInput
        source="fileUpload"
        choices={rights}
        label="Faili üleslaadimine"
      />
      <SelectArrayInput
        source="controllerEvents"
        choices={rights}
        label={uiStringsEt.controllerEventsLabel}
      />
      <SelectArrayInput
        source="user"
        choices={rights}
        label={uiStringsEt.usersLabel}
      />
      <SelectArrayInput
        source="userGroup"
        choices={rights}
        label={uiStringsEt.userGroupsLabel}
      />
      <SelectArrayInput
        source="deviceUpdates"
        choices={rights}
        label={uiStringsEt.deviceUpdatesLabel}
      />
      <SelectArrayInput
        source="sensorEventView"
        choices={limitedRights}
        label={uiStringsEt.sensorEventsLabel}
      />
      <SelectArrayInput
        source="sensorReportView"
        choices={limitedRights}
        label={uiStringsEt.sensorReportsLabel}
      />
    </SimpleForm>
  </Create>
);

export default UserGroupCreate;
