import React from 'react';
import {
  Create,
  CreateProps,
  minValue,
  NumberInput,
  SimpleForm,
  TextInput,
} from 'react-admin';

import { uiStringsEt } from '../../../../i18n/ui.strings';

const DoorModelCreate = (props: CreateProps): React.ReactElement => (
  <Create {...props}>
    <SimpleForm>
      <TextInput label={uiStringsEt.modelNameLabel} source="name" />
      <NumberInput
        label={uiStringsEt.maintenanceCycleLabel}
        source="maintenanceCycle"
        validate={[minValue(0)]}
      />
    </SimpleForm>
  </Create>
);

export default DoorModelCreate;
